import { Control, Controller } from "react-hook-form"
import { normalizeCapacityValue } from "../subscription/SubscriptionUtils"

export const CapacityValInput = ({
  control,
  name = "capacityVal",
  inputCls,
  disabled = false
}: {
  control: Control<any, any>
  name?: string
  inputCls: string
  disabled?: boolean
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: value => {
          const normalizedCapacityValue = normalizeCapacityValue(value)
          if (
            normalizedCapacityValue === "0" ||
            normalizedCapacityValue === ""
          ) {
            return "Capacity is required"
          }
          return true
        }
      }}
      render={({ field }) => (
        <input
          className={inputCls}
          type="text"
          maxLength={9}
          readOnly={disabled}
          {...field}
          onChange={e => {
            let userTypedValue = normalizeCapacityValue(e.target.value)
            e.target.value = userTypedValue
            field.onChange(e)
          }}
        />
      )}
    />
  )
}

export default CapacityValInput
