export const REQUEST_SENT = "REQUEST_SENT"
export const REQUEST_FAILED = "REQUEST_FAILED"
export const REQUEST_SUCCESS = "REQUEST_SUCCESS"

export const PLAN_STANDARD = "STANDARD"
export const PLAN_ENTERPRISE = "ENTERPRISE"
export const PLAN_ENTERPRISE_LITE = "ENTERPRISE-LITE"
export const PLAN_ENTERPRISE_PLUS = "ENTERPRISE-PLUS"
export const PLAN_TRIAL = "TRIAL"
export const UNIT_TB = "TiB"
export const UNIT_PB = "PiB"

export const COMPLETED = "SECTION_COMPLETED"

export const ACCOUNT_TYPE_CUSTOM = "CUSTOM"
export const ACCOUNT_TYPE_SUBSCRIBED = "SUBSCRIBED"

export const SLA_WEEKDAYS = "WEEKDAYS"
export const SLA_ALL_DAYS = "ALL_DAYS"

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  CANCELED: "canceled",
  FAILED_PAYMENT: "past_due"
}
