import {
  ACCOUNT_TYPE_SUBSCRIBED,
  CURRENCY_LIST,
  ISSUE_STATUS_ANSWERED,
  ISSUE_STATUS_ARCHIVED,
  ISSUE_STATUS_OPEN,
  ISSUE_STATUS_WAITING_FOR_RESPONSE,
  NORMAL_ISSUE,
  PANIC_ISSUE,
  PRICING_PLAN_ENTERPRISE,
  PRICING_PLAN_ENTERPRISE_LITE,
  PRICING_PLAN_ENTERPRISE_PLUS,
  PRICING_PLAN_STANDARD,
  RESULTS_CHAR_INTERVAL,
  SEARCH_CHAR_INTERVAL,
  SELF_REPORT_FREQUENCY_IN_DAYS,
  SUBSCRIPTION_PROVIDERS,
  TEST_COMPANY_NAME,
  TWILIO_STATE_CONNECTED,
  DEPLOY_ENV,
  TEST_COMPANY_ACC_ID
} from "./constants"
import fuzzysort from "fuzzysort"
import { charsets, PasswordPolicy } from "password-sheriff"
import history from "./history"
import {
  format,
  endOfDay,
  isValid,
  addDays,
  isBefore,
  compareDesc,
  compareAsc,
  parseISO
} from "date-fns"
import api from "./api"
import {
  PLAN_ENTERPRISE,
  PLAN_ENTERPRISE_LITE,
  PLAN_ENTERPRISE_PLUS,
  PLAN_STANDARD,
  SUBSCRIPTION_STATUS,
  PLAN_TRIAL
} from "./subscription/SubscriptionConstants"
import { format as formatTZ, utcToZonedTime } from "date-fns-tz"
import classNames from "classnames"
import { startTransition } from "react"

const matchString = (message, term) => {
  const index = message.toLowerCase().indexOf(term.toLowerCase())
  return index
}

// find nearest space from beginning to remove half printed words.
const trimFromBeginning = (string, index) => {
  // if we return the string starting from beginning, we don't have to trim
  if (string.length !== 2 * SEARCH_CHAR_INTERVAL) {
    if (string.indexOf(" ") < index) {
      string = string.slice(string.indexOf(" ")).replace(" ", "…")
    } else if (index > 0) {
      /*
              if we don't have a space before index, we can just insert ellipsis at
              the beginning of string, given that index is not at the beginning
            */
      string = "…" + string
    }
  }
  return string
}

const trimFromEnd = (string, index, message) => {
  if (string.lastIndexOf(" ") > index || index < message.length - 1) {
    string = string.slice(0, string.lastIndexOf(" ")) + "…"
  }
  return string
}

const approximateIndex = resultIndexes => {
  // create array of differences between consecutive elems in resultIndexes
  const diffs = resultIndexes.map((curr, idx) => {
    if (resultIndexes[idx + 1]) {
      curr = resultIndexes[idx + 1] - curr
    } else {
      curr = null
    }
    return curr
  })
  diffs.pop()
  // find the position of the smallest difference on result.indexes
  const position = diffs.indexOf(Math.min(...diffs))
  return resultIndexes[position]
}

export const viewMessagePreview = (msg, searchTerm, isAutoComplete) => {
  let charInterval = SEARCH_CHAR_INTERVAL
  if (!isAutoComplete) {
    charInterval = RESULTS_CHAR_INTERVAL
  }
  if (msg.body && msg.body.length < 2 * charInterval) {
    return msg.body
  } else if (msg.body && searchTerm) {
    // check if we have a complete match first
    let index = matchString(msg.body, searchTerm)
    /*
          if not, use fuzzysort module to find closest matches possible
          - this is useful for cases when the search string has filler words or
          the response doesn't contain the exact search query
        */
    if (index === -1) {
      index = 0
      // perform fuzzysort search only on shorter messages
      if (msg.body.length < 1000) {
        const result = fuzzysort.single(searchTerm, msg.body || "")
        if (result && result.indexes) {
          /*
                    in fuzzysort, result.indexes is an array that returns indexes of
                    the matches between the search query and the string being searched.
                    it can be inferred that, if multiple indexes are consecutive, we
                    are closer to an exact match. we can find the differences between
                    consecutive elements of result.indexes and approximate the index of
                    the partial match fairly accurately by taking the lowest difference
                  */
          index = approximateIndex(result.indexes)
        }
      }
    }
    let slicedString
    if (index - charInterval > 0) {
      // if query is in the middle of the message body:
      slicedString = msg.body.substring(
        index - charInterval - searchTerm.length / 2,
        index + charInterval + searchTerm.length / 2
      )
    } else {
      // if query is in the beginning, take first charInterval amount of chars:
      slicedString = msg.body.substring(0, 2 * charInterval)
    }
    /*
          function to insert ellipsis to the beginning of the sliced string
          inserting ellipsis to the end is handled by css attrs. as character
          interval that is being displayed is large enough
        */
    slicedString = trimFromBeginning(slicedString, index)
    if (!isAutoComplete) {
      slicedString = trimFromEnd(slicedString, index, msg.body)
    }
    return slicedString
  } else if (!msg.body && msg.attributes && searchTerm) {
    const attrs = JSON.parse(msg.attributes)
    if (attrs) {
      const fn = attrs.filename
      if (fn && fn.toLowerCase().includes(searchTerm.toLowerCase())) {
        return fn
      }
    }
    return ""
  }
  return ""
}

export const sortName = (activities, isDescending) => {
  return activities.sort((a1, a2) => {
    const a1DisplayName = a1.name.toLowerCase()
    const a2DisplayName = a2.name.toLowerCase()
    if (isDescending) {
      return a2DisplayName > a1DisplayName ? 1 : -1
    } else {
      return a1DisplayName > a2DisplayName ? 1 : -1
    }
  })
}

export const getTextSortFn = (fieldName, isDescending) => {
  return (a1, a2) => {
    const a1FieldValue = (a1[fieldName] || "").toLowerCase()
    const a2FieldValue = (a2[fieldName] || "").toLowerCase()
    if (isDescending) {
      return a2FieldValue > a1FieldValue ? 1 : -1
    } else {
      return a1FieldValue > a2FieldValue ? 1 : -1
    }
  }
}

export const getDateSortFn = (fieldName, isDescending) => {
  return (a1, a2) => {
    const a1FieldValue = parseISO(a1[fieldName]) || new Date(0)
    const a2FieldValue = parseISO(a2[fieldName]) || new Date(0)
    if (isDescending) {
      return compareDesc(a1FieldValue, a2FieldValue)
    } else {
      return compareAsc(a1FieldValue, a2FieldValue)
    }
  }
}

export const sortEmail = (activities, isDescending) => {
  return activities.sort((a1, a2) => {
    const a1Email = a1.email.toLowerCase()
    const a2Email = a2.email.toLowerCase()
    if (isDescending) {
      return a2Email > a1Email ? 1 : -1
    } else {
      return a1Email > a2Email ? 1 : -1
    }
  })
}

export const sortLastMessage = (activities, isDescending) => {
  return activities.sort((a1, a2) => {
    const a1LastMessageSentAt = a1.lastMessageSentAt || new Date(0)
    const a2LastMessageSentAt = a2.lastMessageSentAt || new Date(0)
    if (isDescending) {
      return compareDesc(new Date(a1LastMessageSentAt), a2LastMessageSentAt)
    } else {
      return compareAsc(new Date(a1LastMessageSentAt), a2LastMessageSentAt)
    }
  })
}

export const sortMessageCount = (activities, isDescending) => {
  return activities.sort((a1, a2) => {
    if (isDescending) {
      return a2.msgsSentCount - a1.msgsSentCount
    } else {
      return a1.msgsSentCount - a2.msgsSentCount
    }
  })
}

export const sortByTeamName = (listOfValues, isDescending) => {
  return listOfValues.sort((a, b) => {
    const r1TeamName = (a.teamName || "").toLowerCase()
    const r2TeamName = (b.teamName || "").toLowerCase()
    if (isDescending) {
      return r2TeamName > r1TeamName ? 1 : -1
    } else {
      return r1TeamName > r2TeamName ? 1 : -1
    }
  })
}

export const isNetworkOnline = twilioState => {
  return twilioState === TWILIO_STATE_CONNECTED
}

export const getIssueStatus = (issue = {}, userTypeToCheck = "customer") => {
  const {
    issue_answer_state: {
      is_archived,
      user_type: lastAnswerByUserType,
      state: issueAnswerStatus
    } = {}
  } = issue

  let issueStatus = ISSUE_STATUS_OPEN
  if (is_archived && issueAnswerStatus) {
    issueStatus = ISSUE_STATUS_ARCHIVED
  } else if (issueAnswerStatus) {
    issueStatus = ISSUE_STATUS_ANSWERED
  } else if (lastAnswerByUserType === userTypeToCheck) {
    issueStatus = ISSUE_STATUS_WAITING_FOR_RESPONSE
  }

  return issueStatus
}

export const getIssueType = issue => {
  if (issue.issue_type) {
    return PANIC_ISSUE
  } else {
    return NORMAL_ISSUE
  }
}

export const isAdminUser = userInfo => {
  return userInfo && userInfo.roles && userInfo.roles.includes("admin")
}

export const isAccountOwner = userInfo => {
  return userInfo && userInfo.roles && userInfo.roles.includes("account_owner")
}

export const isSubscribedAccount = accountType => {
  return accountType && accountType.type === ACCOUNT_TYPE_SUBSCRIBED
}

// show new features only to engineers and Gringotts members
export const isUserTester = userInfo => {
  return userInfo.isEngineer || userInfo.companyName === TEST_COMPANY_NAME
}
export const canCreatePanic = userInfo => {
  return userInfo.isEngineer === false
}

export const isTestCompany = userInfo => {
  return userInfo.accountId === TEST_COMPANY_ACC_ID
}

//eslint-disable-next-line
export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ /* eslint-disable-line */

export const getPasswordPolicies = () => {
  const lengthPolicy = new PasswordPolicy({
    length: {
      minLength: 8
    }
  })

  const casePolicy = new PasswordPolicy({
    contains: {
      expressions: [charsets.lowerCase]
    }
  })

  const numberPolicy = new PasswordPolicy({
    contains: {
      expressions: [charsets.numbers]
    }
  })

  const specialCharacterPolicy = new PasswordPolicy({
    contains: {
      expressions: [charsets.specialCharacters]
    }
  })

  return {
    lengthPolicy,
    casePolicy,
    numberPolicy,
    specialCharacterPolicy
  }
}

export function validatePassword(value) {
  const { lengthPolicy, casePolicy, numberPolicy, specialCharacterPolicy } =
    getPasswordPolicies()
  if (!lengthPolicy.check(value)) {
    return "Password must contain at least 8 characters"
  } else if (!casePolicy.check(value)) {
    return "Password must contain at least one lowercase letter"
  } else if (!numberPolicy.check(value)) {
    return "Password must contain at least one number"
  } else if (!specialCharacterPolicy.check(value)) {
    return "Password must contain at least one special character"
  } else return true
}

export const getPasswordPolicyStatus = passwordValue => {
  const { lengthPolicy, casePolicy, numberPolicy, specialCharacterPolicy } =
    getPasswordPolicies()

  let passwordPolicyStatusMap = {}

  if (!lengthPolicy.check(passwordValue)) {
    passwordPolicyStatusMap = {
      ...passwordPolicyStatusMap,
      lengthPolicy: true // rule not met.
    }
  }
  if (!casePolicy.check(passwordValue)) {
    passwordPolicyStatusMap = {
      ...passwordPolicyStatusMap,
      casePolicy: true
    }
  }
  if (!numberPolicy.check(passwordValue)) {
    passwordPolicyStatusMap = {
      ...passwordPolicyStatusMap,
      numberPolicy: true
    }
  }
  if (!specialCharacterPolicy.check(passwordValue)) {
    passwordPolicyStatusMap = {
      ...passwordPolicyStatusMap,
      specialCharacterPolicy: true
    }
  }

  return passwordPolicyStatusMap
}

export function getUTCDate(seconds) {
  function pad(n) {
    return (n < 10 ? "0" : "") + n
  }

  const date = new Date(seconds * 1000)
  const [year, month, day] = [
    date.getUTCFullYear(),
    date.getUTCMonth() + 1,
    date.getUTCDate()
  ]
  return `${year}-${pad(month)}-${pad(day)}`
}

/**
 * Escapes Any regex characters that are part of Search String which would cause @Error Syntax Error: "nothing to repeat"
 * @param searchString
 * @returns escaped String with regex characters.
 */
export function escapeRegEx(searchString) {
  return searchString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&") // $& means the whole matched string
}

// Check for payment failures
export const isSubscriptionPaymentFailed = (subscriptionInfo = {}) => {
  const { service_type } = subscriptionInfo
  if (
    subscriptionInfo &&
    (service_type === PLAN_STANDARD || service_type === PLAN_ENTERPRISE)
  ) {
    return subscriptionInfo.status === "past_due"
  }
}

export function isValidCardCountry(paymentMethodResponse) {
  return (
    paymentMethodResponse.paymentMethod &&
    paymentMethodResponse.paymentMethod.card &&
    paymentMethodResponse.paymentMethod.card.country === "US"
  )
}

export function canResubscribe(userInfo, subscriptionInfo = {}) {
  const { status, service_type: serviceType } = subscriptionInfo
  return (
    userInfo &&
    isAccountOwner(userInfo) &&
    (serviceType === PLAN_TRIAL || status === SUBSCRIPTION_STATUS.CANCELED)
  )
}

export function handleMaintenanceStatus(res) {
  //  OK status response indicates there is no maintenance going on
  startTransition(() => {
    //handle it with transition to avoid async updates
    if (res.status !== "OK") {
      history.push("/maintenance")
    }
  })
}

export const teamNameRegx = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/

export function isValidTeamName(teamName) {
  const result = teamNameRegx.exec(teamName)
  return !!result && result[0] !== null
}

export function canCancelSubscription(userInfo) {
  const isValidAccess =
    isAccountOwner(userInfo) &&
    isSubscribedAccount(userInfo.accountType) &&
    userInfo.accountType.status !== "canceled"

  return isValidAccess
}

export const formatInvoiceAmount = amount => {
  let result = amount / 100

  if (result < 0) {
    result = Math.abs(result)
    return `-$${result}`
  }
  return `$${result}`
}

export const hasInvitePerms = userInfo => {
  return userInfo.isEngineer || userInfo.accountType.status !== "canceled"
}

export const getPlanClassification = issueSubscriptionAccount => {
  let planClassification = {}
  let foundAccount = issueSubscriptionAccount || {
    account_type: {}
  }
  if (foundAccount) {
    const { account_type: { status, serviceType } = {}, is_trial } =
      foundAccount
    const isActive = status === "active"
    const isCancelled = status === "canceled"
    const isFailedPayment = status === "past_due"

    const isTrialPlan = serviceType === PLAN_TRIAL

    const isEnterprise = serviceType === PRICING_PLAN_ENTERPRISE
    const isEnterpriseLite = serviceType === PRICING_PLAN_ENTERPRISE_LITE
    const isEnterprisePlus = serviceType === PRICING_PLAN_ENTERPRISE_PLUS
    const isStandard = serviceType === PRICING_PLAN_STANDARD
    planClassification = {
      isActivePlan: isActive,
      isCancelledPlan: isCancelled,
      isFailedPaymentPlan: isFailedPayment,
      isEnterprisePlan: isEnterprise,
      isEnterpriseLitePlan: isEnterpriseLite,
      isEnterprisePlusPlan: isEnterprisePlus,
      isStandardPlan: isStandard,
      isActiveStandardPlan: isActive && isStandard,
      isActiveEnterprisePlan: isActive && isEnterprise,
      isActiveEnterpriseLitePlan: isActive && isEnterpriseLite,
      isActiveEnterprisePlusPlan: isActive && isEnterprisePlus,
      isActiveTrialPlan: isActive && isTrialPlan,
      isTrialPlan: isTrialPlan,
      isTrial: is_trial || isTrialPlan
    }
  }

  return planClassification
}

export const getStripeKey = () => {
  return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
}

export const getStripeKeyWithEnv = () => {
  return api.getDeployEnv().then(res => {
    if (res.env === DEPLOY_ENV.PROD) {
      return process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    } else if (res.env === DEPLOY_ENV.DEV || res.env === DEPLOY_ENV.STAGING) {
      return process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY
    } else {
      return null
    }
  })
}

const getAsObjectPath = (propertyPath = "", targetObject = {}) => {
  let propertyValue = null
  if (propertyPath.indexOf(".") !== -1) {
    // nested path like a.b.c
    //TODO : nested Arrays are not supported now.

    try {
      propertyValue = propertyPath
        .split(".")
        .reduce((strPropKey, idx) => strPropKey[idx], targetObject)
    } catch (e) {
      propertyValue = ""
    }

    return propertyValue
  } else {
    propertyValue = targetObject[propertyPath]
  }
  return propertyValue
}

export const textComparator = (propertyPath = "", isDescending) => {
  return (a1, a2) => {
    const object1AccessorPath = getAsObjectPath(propertyPath, a1)
    const object2AccessorPath = getAsObjectPath(propertyPath, a2)
    const a1DisplayName = ("" + object1AccessorPath).toLowerCase()
    const a2DisplayName = ("" + object2AccessorPath).toLowerCase()
    if (isDescending) {
      return a2DisplayName > a1DisplayName ? 1 : -1
    } else {
      return a1DisplayName > a2DisplayName ? 1 : -1
    }
  }
}

export const dateComparator = (propertyPath = "", isDescending) => {
  const minDate = new Date(1970, 0, 1)
  const maxDate = new Date(2099, 11, 31)

  return (a1, a2) => {
    let row1Date = getAsObjectPath(propertyPath, a1)
    let row2Date = getAsObjectPath(propertyPath, a2)
    row1Date = new Date(row1Date)
    row2Date = new Date(row2Date)

    if (!isValid(row1Date)) {
      row1Date = isDescending ? minDate : maxDate
    }
    if (!isValid(row2Date)) {
      row2Date = isDescending ? minDate : maxDate
    }

    if (isDescending) {
      return compareDesc(row1Date, row2Date)
    } else {
      return compareAsc(row1Date, row2Date)
    }
  }
}

export const isValidDate = dateStr => {
  const date = new Date(dateStr)
  const date_regex = /^((19|20)\d{2})-((0|1)\d{1})-((0|1|2|3|4)\d{1})/g
  return date_regex.test(dateStr)
    ? isBefore(date, endOfDay(new Date())) && isValid(date)
    : false
}

export const numberComparator = (propertyPath = "", isDescending) => {
  return (a1, a2) => {
    const numberValue1 =
      Number.parseFloat(getAsObjectPath(propertyPath, a1)) ||
      (isDescending ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER)
    const numberValue2 =
      Number.parseFloat(getAsObjectPath(propertyPath, a2)) ||
      (isDescending ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER) // nulls last

    if (isDescending) {
      return numberValue2 - numberValue1
    }
    return numberValue1 - numberValue2
  }
}

export const sortListByPropertyWithDataType = (
  list,
  propertyKey = "",
  isDescending,
  dataType,
  dateTimeFormat
) => {
  //default to text sort
  const listToSort = [...list]
  let curriedComparator = textComparator(propertyKey, isDescending)
  if (dataType === "date") {
    curriedComparator = dateComparator(
      propertyKey,
      isDescending,
      dateTimeFormat
    )
  } else if (dataType === "number") {
    curriedComparator = numberComparator(propertyKey, isDescending)
  }
  return listToSort.sort(curriedComparator)
}

export const validateTeamName = val => {
  if (!isValidTeamName(val)) {
    return "Organization name is not valid"
  }
  return new Promise((resolve, reject) => {
    api
      .validateTeamName(val)
      .then(resolve)
      .catch(err => resolve(err.error))
  })
}

export const validateTeamNameOnUpdate = (val, accountId) => {
  if (!isValidTeamName(val)) {
    return "Organization name is not valid"
  }
  return new Promise((resolve, reject) => {
    api
      .validateTeamName(val, accountId)
      .then(resolve)
      .catch(err => resolve(err.error))
  })
}

export const sendResetPasswordEmailLink = (
  userEmail,
  onProgress = result => {},
  onSuccess = () => {},
  onError = err => {}
) => {
  onProgress(true)
  return api
    .ForgotPassword(userEmail)
    .then(res => {
      onProgress(false)
      onSuccess(userEmail, res)
    })
    .catch(err => {
      onError(err)
      onProgress(false)
    })
}

export const getUTCFormat = inputDate => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
  return `${
    months[inputDate.getUTCMonth()]
  } ${inputDate.getUTCDate()}, ${inputDate.getUTCFullYear()}`
}

export const isSelfReportDue = (subscriptionInfo = {}) => {
  const product = subscriptionInfo.subscribed_products
    ? subscriptionInfo.subscribed_products[0]
    : {}
  const { quantityUpdatedAt } = product || {}
  return isLastUpdatePastDue(quantityUpdatedAt)
}

export const getQuantityUpdateDueDate = quantityUpdatedAt => {
  let jsLstUpdDate = new Date(quantityUpdatedAt)
  const projectedDueDate = addDays(jsLstUpdDate, SELF_REPORT_FREQUENCY_IN_DAYS)

  return projectedDueDate
}

export const isLastUpdatePastDue = quantityUpdatedAt => {
  const jsCurrDate = new Date()
  const projectedDueDate = getQuantityUpdateDueDate(quantityUpdatedAt)
  return isBefore(projectedDueDate, jsCurrDate)
}

export const hasSubscriptionAlert = (subscriptionInfo = {}) => {
  return isSubscriptionPaymentFailed(subscriptionInfo)
}

export const getAsSearchHighlightedText = (sourceText, searchTerm = "") => {
  const escapedTerm = escapeRegEx(searchTerm)
  return sourceText.replace(
    new RegExp(escapedTerm, "gi"),
    //If highlight is required update it to : <mark>${match}</mark>
    match => `${match}`
  )
}

export const userSearchComparator = (searchText = "") => {
  const caseInsensitiveSearchTerm = searchText.toLowerCase()
  return userRecord => {
    let { email = "", name = "" } = userRecord

    email = email.toLowerCase()
    name = name.toLowerCase()

    return (
      name.includes(caseInsensitiveSearchTerm) ||
      email.includes(caseInsensitiveSearchTerm)
    )
  }
}

export const validateEmailValue = value => {
  if (!value) {
    return "Email address is required"
  } else if (value.indexOf(" ") !== -1) {
    return "Spaces are not allowed in email"
  } else if (!emailRegExp.test(value)) {
    return "Please enter a valid email address"
  }
}

export const getFormattedDate = inputDate => {
  const dateFormat = "yyyy-MM-dd"
  let formattedDate = ""
  if (inputDate) {
    formattedDate = format(new Date(inputDate), dateFormat)
  }
  return formattedDate
}

export const getTenantPlanDesc = plan => {
  if (plan === PLAN_STANDARD) {
    return `Standard`
  } else if (plan === PLAN_ENTERPRISE) {
    return `Enterprise`
  } else if (plan === PLAN_ENTERPRISE_LITE) {
    return `Enterprise Lite`
  } else if (plan === PLAN_ENTERPRISE_PLUS) {
    return `Enterprise Plus`
  } else if (plan === PLAN_TRIAL) {
    return `Trial`
  } else {
    return ""
  }
}

export const isChargebeeSubscription = (subscriptionInfo = {}) => {
  const { subscription_provider: subscriptionProvider } = subscriptionInfo
  return subscriptionProvider === SUBSCRIPTION_PROVIDERS.CHARGEBEE
}

export const getSelectedOption = (
  optionList,
  selectedValue,
  isFirstOptionDefault
) => {
  const matchingListItem = optionList.find(option => {
    return option.value === selectedValue
  })

  if (matchingListItem) {
    return matchingListItem
  } else if (!matchingListItem && isFirstOptionDefault) {
    return optionList[0]
  }

  return null
}

export const getNumberFormatter = currencyCode => {
  let currencyCodeWithDefault = currencyCode || CURRENCY_LIST.USD
  const currencyLocale = currencyCodeWithDefault === "EUR" ? "en-DE" : "en-US"

  let numberFormat = new Intl.NumberFormat(currencyLocale, {
    style: "currency",
    currency: currencyCodeWithDefault,
    minimumFractionDigits: 0
  })

  return numberFormat
}

export const formatAmount = (amount, currencyCode) => {
  return getNumberFormatter(currencyCode).format(amount)
}

export const getPlanComparator = (serviceType, currencyCode) => {
  return plan => {
    return (
      plan.service_type_name === serviceType &&
      plan.plan_details.subscription_provider === "CHARGEBEE" &&
      plan.plan_details.currency_code === currencyCode
    )
  }
}

export const formatAsDate = (inputDate, defaultValue) => {
  if (!inputDate && defaultValue) {
    return defaultValue
  }

  let dateObject = new Date(inputDate)
  let formattedDate = ""
  try {
    formattedDate =
      inputDate !== null && isValid(dateObject) ? getUTCFormat(dateObject) : ""
  } catch (e) {
    formattedDate = ""
  }

  return formattedDate
}

export const getFileExtension = (fileName = "") => {
  const fileExtension = fileName
    .substr(fileName.lastIndexOf(".") + 1)
    .toUpperCase()

  return fileExtension
}

export const isImage = (mimeType = "") => {
  const isImageType = mimeType.indexOf("image") !== -1
  return isImageType
}

export const validateDocTitle = (value = "") => {
  if (!value.trim().length) {
    return "Document title is required."
  }
  return true
}

export const downloadClusterRecentFile = (accountId, clusterId, fileType) => {
  api
    .getClusterRecentFiles(accountId, clusterId, fileType)
    .then(hr => {
      if (hr.length === 1) {
        const { uuid, filename } = hr[0]
        downloadFile(accountId, fileType, uuid, filename)
      } else {
        console.log("expected one recent health report, found: " + hr.length)
      }
    })
    .catch(err => {
      console.log("error in fetching recent health report: " + err.error)
    })
}

export const downloadFile = (accountId, filetype, uuid, filename) => {
  const url = api.getFileUrl(accountId, filetype, uuid, filename)
  const fullUrl = window.location.origin + url
  downloadFromUrl(fullUrl, filename)
}

export const validateStartDate = date => {
  if (!isValidDate(date)) {
    return "Invalid start date"
  }
}

export const validateEndDate = (dateStr, startDate) => {
  const date_regex = /^((19|20)\d{2})-((0|1)\d{1})-((0|1|2|3|4)\d{1})/g
  if (dateStr) {
    return date_regex.test(dateStr)
      ? validateAndCompareWithStartDate(dateStr, startDate)
      : "End date is invalid"
  } else {
    return true
  }
}

const validateAndCompareWithStartDate = (value, startDate) => {
  const endDateBeforeStartMsg = "End date should be after start date."
  if (!startDate && value) {
    return endDateBeforeStartMsg
  }
  if (value) {
    const date = new Date(value)

    if (!isValid(date)) {
      return "Invalid end date"
    }
    if (value && isBefore(date, new Date(startDate))) {
      return endDateBeforeStartMsg
    }
  }
  return true
}

export function getChargeableQty(inputQty, planMinQty, planMaxQty) {
  if (inputQty < planMinQty) {
    return planMinQty
  }
  if (planMaxQty > 0 && inputQty > planMaxQty) {
    return planMaxQty
  }
  return inputQty
}

export function getPlanEstimate(value, product) {
  let usrEnteredQty = Number.isNaN(value) ? product.min_quantity : value
  let chargeableQty = getChargeableQty(
    usrEnteredQty,
    product.min_quantity,
    product.max_quantity
  )
  let estimatedCost = chargeableQty * product.unit_price
  return `${formatAmount(estimatedCost, product.plan_details.currency_code)}`
}

export const FEATURE_NAMES = {
  ALERTS_HUB: "ALERTS_HUB",
  MFA: "MFA",
  TODO: "TODO",
  CLUSTER_EXPLORER: "CLUSTER_EXPLORER"
}

export const isFeatureEnabled = (
  userInfo,
  appEnv = [],
  featureName = "",
  allowCheckFn
) => {
  if (!allowCheckFn) {
    if (isUserTester(userInfo)) {
      return true
    }
  } else {
    return allowCheckFn()
  }

  /* Example Feature configuration
       health: {
        [DEPLOY_ENV.DEV]: ["*"],
        [DEPLOY_ENV.STAGING]: ["*"],
        [DEPLOY_ENV.PROD]: ["*"] //add account id here. e.g: 151 // 2-Spacely, 151-Paypal, 1- Gringotts
      }
      */

  const featureByAcc = {
    [FEATURE_NAMES.TODO]: {
      [DEPLOY_ENV.DEV]: ["*"],
      [DEPLOY_ENV.STAGING]: ["*"],
      [DEPLOY_ENV.PROD]: [1]
    },
    [FEATURE_NAMES.CLUSTER_EXPLORER]: {
      [DEPLOY_ENV.DEV]: ["*"],
      [DEPLOY_ENV.STAGING]: ["*"],
      [DEPLOY_ENV.PROD]: [1]
    }
  }

  const { accountId } = userInfo
  const featureConfig = featureByAcc[featureName] || {}
  const featureAccList = featureConfig[appEnv] || []

  const isAllowedAcc =
    featureAccList[0] === "*" || featureAccList.includes(accountId)

  return isAllowedAcc
}

export const utcDateToDisplay = (date, format = "MMM. d, yyyy hh:mm a") => {
  if (date === null) {
    return null
  }
  const parsedDate = new Date(date)
  const dateInUTC = utcToZonedTime(parsedDate, "UTC")
  return formatTZ(dateInUTC, format, { timeZone: "UTC" })
}

export const getAccountTypeText = (accountType, isTrial) => {
  return accountType === ACCOUNT_TYPE_SUBSCRIBED
    ? "Recurring"
    : `${isTrial ? "Custom (Trial)" : "Custom"}`
}

export const isChunkLoadingError = message => {
  const chunkFailedMessageRegex = /Loading chunk [\d]+ failed/
  const cssChunkFailedMessageRegex = /Loading CSS chunk [\d]+ failed/
  const unexpectedTokenRegex = /Unexpected token '<'/
  if (!message) {
    return false
  }
  if (
    chunkFailedMessageRegex.test(message) ||
    cssChunkFailedMessageRegex.test(message) ||
    unexpectedTokenRegex.test(message)
  ) {
    return true
  } else {
    return false
  }
}

export const isTwilioError = message => {
  const transitionErrorRegex1 =
    /Non-Error exception captured with keys: current, from, message, to, transition/
  const transitionErrorRegex2 =
    /Non-Error promise rejection captured with keys: current, from, message, to, transition/
  const retrierErrorRegex = /Retrier attempt is already in progress/
  if (!message) {
    return false
  }
  if (
    transitionErrorRegex1.test(message) ||
    transitionErrorRegex2.test(message) ||
    retrierErrorRegex.test(message)
  ) {
    return true
  } else {
    return false
  }
}

export const saveBlob = (blob, fileName) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, fileName)
  } else {
    const downloadLinkEl = document.createElement("a")
    if (downloadLinkEl.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const downloadUrl = URL.createObjectURL(blob)
      downloadFromUrl(downloadUrl, fileName)
    }
  }
}

// Check if the OS is MacOS or not
export const isMac = () => {
  return navigator.platform.toUpperCase().startsWith("MAC")
}

// Keyboard shortcut for input focus shortcut
export const focusInput = ref => event => {
  let metaKey = isMac() ? event.metaKey : event.ctrlKey

  if (metaKey && event.key === "/") {
    ref.focus()
  }
}

export async function checkLoggedInUser(emailFromUrl, callback) {
  try {
    if (api.LoggedIn()) {
      // fetch if only api says logged in.
      const loggedInUserInfo = await api.GetUserInfo()
      if (loggedInUserInfo.email !== emailFromUrl) {
        api.Logout()
        callback && callback()
      }
    }
  } catch (err) {
    api.Logout()
  }
}

// Boxed bg for OTP inputs
export const otpBg = code => {
  return [...Array(6).keys()].map(
    (
      i // 6 = number of OTP digits
    ) => (
      <i
        key={i}
        className={classNames({
          active: code.length > i
        })}
      />
    )
  )
}

export function removeQueryString(fileName) {
  let formattedFileName = fileName
  const queryParamIndex = formattedFileName.indexOf("?")
  //trim off any query params that could have been added if copy pasted.
  if (queryParamIndex !== -1) {
    formattedFileName = formattedFileName.substr(0, queryParamIndex)
  }
  return formattedFileName
}

export function getChargebeeSite() {
  const prodOrigins = ["http://subnet.min.io", "https://subnet.min.io"]
  const curOrigin = window.location.origin
  const isProd = prodOrigins.includes(curOrigin)
  return isProd ? "minio" : "minio-test"
}

export const isZoomLink = url => {
  const regex = /^https:\/\/([a-zA-Z0-9-]+\.)?zoom.us\/j\/.+?\b/g
  return regex.test(url)
}

export const downloadFromUrl = (downloadUrl, fileName) => {
  const downloadLinkEl = document.createElement("a")
  downloadLinkEl.setAttribute("href", downloadUrl)
  downloadLinkEl.setAttribute("download", fileName)
  downloadLinkEl.style.visibility = "hidden"
  document.body.appendChild(downloadLinkEl)
  downloadLinkEl.click()
  document.body.removeChild(downloadLinkEl)
}
