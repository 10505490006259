import { useState, useEffect } from "react"

function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false)

  const onKeyDown = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  const onKeyUpHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown)
    window.addEventListener("keyup", onKeyUpHandler)
    return () => {
      window.removeEventListener("keydown", onKeyDown)
      window.removeEventListener("keyup", onKeyUpHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return keyPressed
}

export default useKeyPress
