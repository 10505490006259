import React, { Suspense } from "react"
import api from "./api"
import { Route, Switch, Redirect, useLocation } from "react-router-dom"
import { PRICING_PAGE_URL, ROUTE_PATHS } from "./constants"

const Console = React.lazy(() => import("./Console"))
const Login = React.lazy(() => import("./Login"))
const SignupByInvite = React.lazy(() => import("./user/SignupByInvite"))
const ResetPassword = React.lazy(() => import("./login/ResetPassword"))
const MaintenancePage = React.lazy(() =>
  import("./maintenance/MaintenancePage")
)
const MaintenanceAdmin = React.lazy(() =>
  import("./maintenance/MaintenanceAdmin")
)
const DatedPrivacyPolicyDocument = React.lazy(() =>
  import("./documents/DatedPrivacyPolicyDocument")
)
const DatedTnCDocument = React.lazy(() =>
  import("./documents/DatedTnCDocument")
)
const CreateAccount = React.lazy(() => import("./chargebee/CreateAccount"))
const AddOrganization = React.lazy(() => import("./chargebee/AddOrganization"))
const JoinOrganization = React.lazy(() => import("./user/JoinOrganization"))
const ForgotPassword = React.lazy(() => import("./login/ForgotPassword"))
const OneTimePasswordPage = React.lazy(() =>
  import("./login/OneTimePasswordPage")
)

const App = () => {
  const location = useLocation()
  return (
    <Suspense fallback={<div className="loading" />}>
      <Switch>
        <Route exact path={"/"} children={<Login />} />
        <Route exact path={"/forgot-password"} children={<ForgotPassword />} />
        <Route exact path={"/signup"} children={<SignupByInvite />} />
        <Route
          exact
          path={ROUTE_PATHS.CREATE_ACCOUNT}
          children={<CreateAccount />}
        />
        <Route
          exact
          path={ROUTE_PATHS.ADD_ORGANIZATION}
          children={<AddOrganization />}
        />
        <Route
          exact
          path={ROUTE_PATHS.JOIN_ORGANIZATION}
          children={<JoinOrganization />}
        />
        <Route exact path={"/reset-password"} children={<ResetPassword />} />
        <Route
          exact
          path={"/subscribe"}
          children={() => {
            window.location = `https://${PRICING_PAGE_URL}`
            return null
          }}
        />
        <Route exact path={"/mnt"} children={<MaintenanceAdmin />} />
        <Route exact path={"/maintenance"} children={<MaintenancePage />} />
        <Route
          exact
          path={"/privacy-policy/:effectiveDate?"}
          children={<DatedPrivacyPolicyDocument />}
        />
        <Route
          exact
          path={"/terms-and-conditions/:type/:effectiveDate?"}
          children={<DatedTnCDocument />}
        />
        <Route exact path="/otp" children={<OneTimePasswordPage />} />
        <Route
          path={"/*"}
          children={() => {
            if (api.LoggedIn()) {
              return <Console />
            } else {
              const { state = {}, pathname, search } = location
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: {
                      ...state,
                      redirectTo: state.redirectTo || `${pathname}${search}`
                    }
                  }}
                />
              )
            }
          }}
        />
      </Switch>
    </Suspense>
  )
}

export default App
