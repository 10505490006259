import { saveBlob } from "../utils"
import { addDays, differenceInDays } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"

export const STORAGE_UNITS = [
  "B",
  "KiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB"
]

//To convert units lower than TiB to a fractional TiB.
export const CAPACITY_DISPLAY_MULTIPLIER: Record<string, number> = {
  B: 1099511627776,
  KiB: 1073741824,
  MiB: 1048576,
  GiB: 1024,
  TiB: 1,
  PiB: 1,
  EiB: 1,
  ZiB: 1,
  YiB: 1
}

export const TO_BYTE_MULTIPLIER: Record<string, number> = {
  B: 1,
  KiB: 1024,
  MiB: 1048576,
  GiB: 1073741824,
  TiB: 1099511627776,
  PiB: 1125899906842624,
  EiB: 1152921504606847000,
  ZiB: 1.1805916207174113e21,
  YiB: 1.2089258196146292e24
}

export function getClusterCapacityInBytes(inputValue = 0, unit: any = "TiB") {
  return inputValue * TO_BYTE_MULTIPLIER[unit]
}

export const normalizeCapacity = (num: number, addSpace: boolean) => {
  if (Math.abs(num) < 1) return num + (addSpace ? " " : "") + STORAGE_UNITS[0]

  const exponentValue = Math.floor(Math.log(num) / Math.log(1024))
  const exponent = Math.min(exponentValue, STORAGE_UNITS.length - 1)

  const normalizedBytes = (num < 0 ? -num : num) / 1024 ** exponent

  return {
    normalizedBytes,
    exponent
  }
}

const formatDisplayBytes = (
  num: number,
  normalizedBytes: number,
  addSpace: boolean,
  exponent: number
) => {
  return (
    (num < 0 ? "-" : "") +
    normalizedBytes +
    (addSpace ? " " : "") +
    (STORAGE_UNITS[exponent] || "")
  )
}
export const formatBytes = (num: number, precision = 3, addSpace = true) => {
  const { normalizedBytes = 0, exponent = STORAGE_UNITS[0] }: any =
    normalizeCapacity(num, addSpace)

  return formatDisplayBytes(
    num,
    normalizedBytes.toFixed(precision),
    addSpace,
    exponent
  )
}

export function applyUnitRounding(inputValue: number) {
  let roundedValue = "0"
  const unitTB = STORAGE_UNITS[4]
  let { normalizedBytes = 0, exponent }: any = normalizeCapacity(
    inputValue,
    false
  )

  let uoM: any = STORAGE_UNITS[exponent] || STORAGE_UNITS[0]

  const unitIndex = STORAGE_UNITS.indexOf(uoM)
  let unitOfMeasure = unitTB

  if (unitIndex >= 5) {
    // PB and Above.
    unitOfMeasure = STORAGE_UNITS[exponent]
    roundedValue = normalizedBytes.toFixed(2)
  } else {
    const toTBMultiplier = CAPACITY_DISPLAY_MULTIPLIER[uoM]
    //TiB and below
    if (uoM !== unitTB && inputValue > 0) {
      roundedValue = "1"
      unitOfMeasure = unitTB
    } else {
      const inTB = normalizedBytes * toTBMultiplier
      roundedValue = Math.ceil(Math.round(inTB * 100) / 100).toFixed(0)
    }
  }

  return {
    roundedValue: roundedValue,
    unitOfMeasure: unitOfMeasure
  }
}

export function groupBy(list: any[], keyGetter: any) {
  const map = new Map()
  list.forEach((item: any) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const HEALTH_FILE_UPLOAD_FORM_STEPS = {
  UPLOAD: "UPLOAD",
  UPLOAD_PREVIEW: "UPLOAD_PREVIEW",
  WAIT_FOR_UPLOAD: "WAIT_FOR_UPLOAD",
  UPLOAD_REMOVED: "UPLOAD_REMOVED",
  PROMPT_NAME: "PROMPT_NAME"
}

export const NAME_PROMPT_TYPES = {
  PROMPT_AND_UPLOAD: "PROMPT_AND_UPLOAD",
  UPLOAD_AND_PROMPT: "UPLOAD_AND_PROMPT"
}

export const saveLicense = (value: string) => {
  const asBlob = new Blob([value])
  saveBlob(asBlob, `minio.license`)
}

export const getDiffDays = (attachmentDate: string, expDays: number) => {
  return differenceInDays(
    addDays(new Date(attachmentDate), expDays),
    new Date()
  )
}
export const formatExpiryMessage = (days: number) => {
  let message = ""

  if (days < 0) {
    message = `Expired ${Math.abs(days)} ${
      days === -1 ? "day" : "days"
    } ago. Will be deleted soon.`
    return message
  }
  if (days === 0) {
    message = "Expired today. Will be deleted soon."
  } else {
    message = `Expires in ${days}  ${days === 1 ? "day" : "days"}`
  }

  return `${message}.`
}

export const FILE_DATE_TIME_FORMAT = "do MMMM yyyy HH:mm zzz"

export const formatDateToUTC = (date: string) => {
  const utcDate: string = `${formatInTimeZone(
    new Date(date),
    "UTC",
    FILE_DATE_TIME_FORMAT
  )}`

  return `${utcDate}`
}

export const pluralizeValueText = (val: number, text: string) => {
  return val > 1 ? `${text}s` : text
}

export const formatAsCompactNumber = (number: number) => {
  const numFormatter = Intl.NumberFormat("en", { notation: "compact" })
  return numFormatter.format(number)
}
