import React, { useEffect, useState } from "react"
import useModalState from "../hooks/useModalState"
import PortalContainer from "../components/PortalContainer/PortalContainer"
import Modal from "../components/Modal"
import api from "../api"
import { getTermsAndConditionsUrl } from "./SubscriptionUtils"
import { ACCOUNT_TYPE_SUBSCRIBED } from "../constants"

const ClusterHealthDueAlert = ({
  clusters = [],
  message = ""
}: {
  clusters: string[]
  message: string
}) => {
  const [isOpen, openModal, closeModal] = useModalState()

  return (
    <div className="inline items-center justify-center gap-1 md:flex">
      <div className="inline">{message}</div>

      <div className="flex items-center gap-1">
        <a
          href="#/"
          className="cursor-pointer font-medium text-white underline hover:text-white"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            openModal()
          }}
        >
          Click here
        </a>{" "}
        to check the clusters that need action.
      </div>

      {isOpen ? (
        <PortalContainer>
          <Modal onClose={closeModal} title={"Health report due clusters"}>
            <div className="slim-scroll flex max-h-[400px] flex-col overflow-auto ">
              {clusters.map((c, index) => {
                return (
                  <div
                    className="flex items-center gap-2 border-b border-gray-100 p-2"
                    key={c}
                  >
                    <span>{index + 1}.</span>
                    <span className={"truncate"}>{c}</span>
                  </div>
                )
              })}
            </div>
          </Modal>
        </PortalContainer>
      ) : null}
    </div>
  )
}

export const getHealthReportAlert = (
  alertType: string,
  alertMsg: string,
  clusterNames?: string
) => {
  const clusters = (clusterNames || "").split(", ")
  let message = alertMsg
  if (clusters.length === 1) {
    const [firstCluster = ""] = clusters
    message = `SUBNET Health report update is past the due for ${firstCluster} cluster.`
    return {
      type: alertType,
      message: message
    }
  }
  if (clusters.length > 1) {
    message = `SUBNET Health report update is past the due date for  ${clusters.length} clusters.`
    return {
      type: alertType,
      messageComponent: (
        <ClusterHealthDueAlert clusters={clusters} message={message} />
      )
    }
  }

  return {
    type: alertType,
    message: alertMsg
  }
}

const TncUpdateAlert = ({
  alertId,
  alertMsg,
  clearAlert
}: {
  alertId: number
  alertMsg: string
  clearAlert: VoidFunction
}) => {
  const [tnc, setTnc] = useState<any>()
  useEffect(() => {
    api
      .getAcceptedPolicyDocs()
      .then((list: any[]) => {
        console.log(list)
        const tncDoc = list.find(p => {
          return p.doc_type === "Terms & Conditions"
        })
        setTnc(tncDoc)
      })
      .catch((err: any) => {
        console.log("Error in getting accepted policy docs", err)
      })
  }, [])

  function onOk() {
    api
      .markAlertAsRead(alertId)
      .then(() => {
        clearAlert()
      })
      .catch((err: any) => {
        console.log("Error in updating alert: ", err)
      })
  }
  const tncLink = tnc
    ? `${getTermsAndConditionsUrl(ACCOUNT_TYPE_SUBSCRIBED, tnc.service_type)}/${
        tnc.policy_effective_from
      }`
    : null
  return (
    <div className="flex items-center justify-center gap-1 p-5">
      <div>
        Please review the revised
        <a
          href={tncLink || "#"}
          target="_blank"
          className="ml-2 text-white underline hover:text-white"
          rel="noreferrer"
        >
          Terms and Conditions.
        </a>
      </div>
      <button
        className="button button--light button--sm ml-2 font-bold ring-gray-100 focus-visible:ring-4"
        onClick={onOk}
      >
        OK
      </button>
    </div>
  )
}

export const getTncUpdateAlert = (
  alertType: string,
  alertId: number,
  alertMsg: string,
  clearAlert: VoidFunction
) => {
  return {
    type: alertType,
    messageComponent: (
      <TncUpdateAlert
        alertId={alertId}
        alertMsg={alertMsg}
        clearAlert={clearAlert}
      />
    )
  }
}
