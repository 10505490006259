import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../api"
import { RootState } from "../configure-store"

export type CategoriesStateType = {
  categories: {
    categories: CategoryInfo[]
  }
  loading: boolean
  error: null | any
}

const initialState: CategoriesStateType = {
  categories: {
    categories: []
  },
  loading: false,
  error: null
}

export type CategoryInfo = {
  description: string
  id: number
  level: number
  name: string
}

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      return await api.listCategories()
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

const categoriesSlice = createSlice({
  name: "clusters",
  initialState,
  reducers: {
    resetCategories: state => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(getCategories.pending, state => {
        state.error = null
        state.loading = true
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        const normalizedObj = action.payload?.reduce(
          (acc: Record<any, any>, val: any) => {
            acc[val.id] = val
            return acc
          },
          {}
        )

        state.loading = false
        state.categories = normalizedObj
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.error = action.payload
        state.loading = false
      })
  }
})

export const categoriesSelectorFn = (state: RootState) =>
  state.categories.categories || {}

export const categoriesOptionsSelectorFn = (state: RootState) => {
  const levelsMap: Record<number, CategoryInfo> =
    state.categories?.categories || {}
  const optionsList = Object.keys(levelsMap).map((levelId: any) => {
    const catOpt = levelsMap[levelId]
    return {
      value: catOpt.id,
      label: catOpt.name,
      description: catOpt.description,
      level: catOpt.level
    }
  })

  return optionsList
}

export const { resetCategories } = categoriesSlice.actions

export default categoriesSlice.reducer
