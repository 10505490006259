import {
  PLAN_ENTERPRISE,
  PLAN_STANDARD,
  UNIT_TB,
  PLAN_TRIAL,
  ACCOUNT_TYPE_SUBSCRIBED,
  PLAN_ENTERPRISE_LITE,
  PLAN_ENTERPRISE_PLUS
} from "./SubscriptionConstants"
import {
  TERMS_AND_CONDITIONS_URL_STANDARD,
  TERMS_AND_CONDITIONS_URL_ENTERPRISE,
  TERMS_AND_CONDITIONS_URL_TRIAL
} from "../constants"

export function getPrice(plan: string) {
  if (plan === PLAN_STANDARD) {
    return 10
  } else if (plan === PLAN_ENTERPRISE) {
    return 20
  }
}

/*
For Standard Plan: Min Capacity: 25TiB
For Enterprise Plan:  Min Capacity: 100TiB
*/

export function getMinCapacityForPlan(plan: string) {
  if (plan === PLAN_ENTERPRISE) {
    return 400 //TiB
  }
  return 25 //TiB
}

export function capacityInTB(capacityVal: number, capacityUnit: string) {
  return capacityUnit === UNIT_TB ? capacityVal : capacityVal * 1000
}

export function getTermsAndConditionsUrl(accountType: string, plan: string) {
  if (plan === PLAN_TRIAL) {
    return TERMS_AND_CONDITIONS_URL_TRIAL
  }

  if (accountType === ACCOUNT_TYPE_SUBSCRIBED) {
    if (plan === PLAN_STANDARD) {
      return TERMS_AND_CONDITIONS_URL_STANDARD
    } else {
      return TERMS_AND_CONDITIONS_URL_ENTERPRISE
    }
  }
}

export function getCapacityAsNumber(inValue: string) {
  let capacityValue = Number.parseInt(inValue)
  if (Number.isNaN(capacityValue)) {
    capacityValue = 0
  }
  return capacityValue
}

export function normalizeCapacityValue(inValue: string) {
  let normalizedCapacity = String(inValue).replace(/\D/g, "") // Allow Only Digits.
  normalizedCapacity = normalizedCapacity.replace(/^0+/, "") //  starting with zero is not allowed.
  return normalizedCapacity
}

export function isTrialSupported(plan: string) {
  return [PLAN_ENTERPRISE_LITE, PLAN_ENTERPRISE_PLUS, PLAN_ENTERPRISE].includes(
    plan
  )
}
