import React, { ReactNode } from "react"
import classNames from "classnames"
import FocusLock from "react-focus-lock"
import useKeyPress from "../hooks/useKeyPress"

type ModalProps = {
  children: ReactNode
  onClose: (e: any) => void
  size?: "sm" | "md" | "lg" | "auto" | "full" | string
  padding?: boolean
  className?: string
  title: string | React.ReactElement
  showCloseButton?: boolean
  closeButtonClassName?: string
  wrapperClassName?: string
}

export const Modal = ({
  children,
  onClose,
  size,
  padding = true,
  className = "",
  wrapperClassName = "",
  title = "",
  showCloseButton = true,
  closeButtonClassName = ""
}: ModalProps) => {
  const escKey = useKeyPress("Escape")
  if (escKey) {
    onClose(null)
  }

  const modalSize = `modal--${size}`

  const isTitleElement = typeof title === "object"

  return (
    <FocusLock>
      <div
        className={classNames({
          modal: true,
          [modalSize]: !!size,
          [wrapperClassName]: true
        })}
      >
        <div
          className={classNames({
            [className]: !!className,
            modal__content: true,
            "p-12 sm:p-16": padding
          })}
        >
          {showCloseButton && (
            <button
              type="button"
              onClick={e => {
                e.preventDefault()
                onClose(e)
              }}
              className={classNames({
                "modal__close focus-visible:bg-gray-100": true,
                [closeButtonClassName]: !!closeButtonClassName
              })}
            />
          )}
          {!isTitleElement && title ? (
            <h2 className="mb-10 truncate text-3xl font-medium">{title}</h2>
          ) : (
            title
          )}

          {children}
        </div>
      </div>
    </FocusLock>
  )
}

export default Modal
