import { createPortal } from "react-dom"
import usePortal from "../../hooks/usePortal"

/**
 * @example
 * <PortalContainer>
 *   <>Any Child Component like modals, tooltips.</>
 * </PortalContainer>
 */
const PortalContainer = ({
  portalTargetId,
  children,
  parentElProps = {}
}: {
  portalTargetId?: string
  children: any
  parentElProps?: Record<any, any>
}) => {
  const target = usePortal(portalTargetId, parentElProps)
  if (target) {
    return createPortal(children, target)
  }
  return null
}

export default PortalContainer
