import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ClusterTabAnalysis } from "./types"
import api from "../api"

export interface GetHealthAnalysisParameters {
  accountId: number
  clusterId: number
  reqType: string
  progressCallback: (loading: boolean, err?: any) => any
}

export interface FetchError {
  error: string
  offline: boolean
  status: number
}

export interface ClustersTabState {
  loadingAnalysis: boolean
  analysis: ClusterTabAnalysis | null
  accountId: number | null
  clusterId: number | null
  errorState: string
}

const initialState: ClustersTabState = {
  loadingAnalysis: false,
  analysis: null,
  accountId: null,
  clusterId: null,
  errorState: ""
}

export const getTabHealthAsync = createAsyncThunk<
  ClusterTabAnalysis,
  GetHealthAnalysisParameters,
  { rejectValue: FetchError }
>(
 "clustersTab/getTabHealthAsync",
  async (args: GetHealthAnalysisParameters, { rejectWithValue }) => {
    const { accountId, clusterId, reqType, progressCallback } = args
    progressCallback(true)
    return api
      .analyzeHealth({
        accountId: accountId,
        clusterId: clusterId,
        reqType: reqType
      })
      .then((analyzeReport: ClusterTabAnalysis) => {
        progressCallback(false)
        return analyzeReport
      })
      .catch((err: any) => {
        progressCallback(false, err)
        return rejectWithValue(err)
      })
  }
)

export const clusterTabSlice = createSlice({
  name: "clusters",
  initialState,
  reducers: {
    resetClusterTab: (state: ClustersTabState) => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(getTabHealthAsync.pending, (state, action) => {
        state.loadingAnalysis = true
        state.accountId = action.meta.arg.accountId
        state.clusterId = action.meta.arg.clusterId
        state.errorState = ""
      })
      .addCase(getTabHealthAsync.rejected, (state, action) => {
        state.loadingAnalysis = false
        if (action.payload) {
          let payload: FetchError = action.payload
          state.errorState = payload.error
        }
      })
      .addCase(getTabHealthAsync.fulfilled, (state, action) => {
        state.loadingAnalysis = false
        state.analysis = action.payload
        state.accountId = action.meta.arg.accountId
        state.clusterId = action.meta.arg.clusterId
      })
  }
})

export const { resetClusterTab } = clusterTabSlice.actions
export default clusterTabSlice.reducer

