import { differenceInHours } from "date-fns"
import api from "./api"

export class AccessManager {
  init() {
    this.timer = setInterval(() => this.checkAndRenewAccessToken(), 60 * 1000)
  }
  checkAndRenewAccessToken = async () => {
    // Renew the access token 6 hours before the expiry
    const expAt = api.getTokenExpiresAt()
    if (
      api.LoggedIn() &&
      (expAt === null ||
        differenceInHours(new Date(parseFloat(expAt)), new Date()) < 6)
    ) {
      console.log(
        "Access token expires in ",
        differenceInHours(new Date(parseFloat(expAt)), new Date())
      )
      await api.refreshAccessToken({ ifAvailable: true })
    }
  }
  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}

export default AccessManager
