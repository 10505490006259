import { ROUTE_PATHS } from "./constants"
import history from "./history"

export const getIssueLink = (issueNumber, msgIndex) => {
  if (msgIndex !== undefined) {
    return `/issues/${issueNumber}?msgIndex=${msgIndex}`
  } else {
    return `/issues/${issueNumber}`
  }
}

export const getIssueLinkFull = (issueNumber, msgIndex) => {
  return `${window.location.protocol}//${window.location.host}${getIssueLink(
    issueNumber,
    msgIndex
  )}`
}

export const getMessageLink = (issueNumber, msgIndex) => {
  return `/issues/${issueNumber}?msgIndex=${msgIndex}`
}

export const gotoIssue = (issueNumber, msgIndex) => {
  if (msgIndex !== undefined) {
    history.push(`/issues/${issueNumber}?msgIndex=${msgIndex}`)
  } else {
    history.push(`/issues/${issueNumber}`)
  }
}

export const gotoSupportHome = () => {
  history.push(ROUTE_PATHS.ISSUES)
}

export const goToHealth = () => {
  history.push(ROUTE_PATHS.HEALTH)
}

export const gotoPanic = () => {
  history.push(ROUTE_PATHS.PANIC)
}
export const gotoSubscription = () => {
  history.push(ROUTE_PATHS.SUBSCRIPTION)
}

export const goToCluster = (account_id, cluster_id) => {
  history.push(ROUTE_PATHS.HEALTH + `/${account_id}/${cluster_id}`)
}

export const getCustomerDetailsLink = accountId => {
  return ROUTE_PATHS.CUSTOMERS + `/${accountId}`
}

export const goToClusterRegistration = () => {
  history.push(ROUTE_PATHS.REGISTER_CLUSTER)
}

export const goToFiles = () => {
  history.push(ROUTE_PATHS.FILES)
}
