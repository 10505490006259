import { useRef, useEffect } from "react"

function createPortalRootElement(id) {
  const rootContainer = document.createElement("div")
  rootContainer.setAttribute("id", id)
  return rootContainer
}

function addNewPortalRoot(rootElem) {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling
  )
}

function usePortal(id, parentElProps) {
  const rootElemRef = useRef(null)

  useEffect(function setupElement() {
    // Find existing portal target if any to append
    const existingParent = document.querySelector(`#${id}`)
    const parentElem = existingParent || createPortalRootElement(id)

    // If there is no existing target, add a new one.
    if (!existingParent) {
      addNewPortalRoot(parentElem)
    }

    // Add the portal element to the parent
    parentElem.appendChild(rootElemRef.current)

    return function removeElement() {
      rootElemRef.current.remove()
      if (parentElem.childNodes.length === -1) {
        parentElem.remove()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getPortalRootElement() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement("div")
      // eslint-disable-next-line
      for (let key in parentElProps) {
        rootElemRef.current.setAttribute(key, parentElProps[key])
      }
    }
    return rootElemRef.current
  }

  return getPortalRootElement()
}

export default usePortal
