import "focus-visible" // :focus-visible polyfill for Safari.
import "intersection-observer"
import "raf/polyfill"
import "navigator.locks" //polyfill for navigator.locks in old browser versions
import React from "react"
import ReactDOM from "react-dom/client"
import { Router } from "react-router-dom"
import { Provider } from "react-redux"
import "core-js/stable"
import "regenerator-runtime/runtime"
import "./scss/styles.scss"
import App from "./js/App"
import api from "./js/api"
import history from "./js/history"
import * as Sentry from "@sentry/react"
import { store } from "./js/configure-store"
import { isChunkLoadingError, isTwilioError } from "./js/utils"
import { DEPLOY_ENV } from "./js/constants"

api
  .getDeployEnv()
  .then(res => {
    if (res.env === DEPLOY_ENV.PROD || res.env === DEPLOY_ENV.STAGING) {
      Sentry.init({
        dsn: "https://8eadd96b886f4675ae147ac500b52d57@o615565.ingest.sentry.io/5750091",
        logger: "SubnetFrontendLogger",
        environment: res.env,
        allowUrls: ["https://subnet.min.io"],
        beforeSend: (evnet, hint) => {
          const error = hint.originalException
          // - Ingore script loading errors which happen due app re-deployment
          //   These errors are already handled in AppErrorBoundary.js
          //
          // - Ingore unhandled errors from twilio
          //   We cannot handle them since they are async exceptions
          if (
            error &&
            error.message &&
            (isChunkLoadingError(error.message) || isTwilioError(error.message))
          ) {
            return null
          }
        }
      })
      Sentry.setTag("logger", "SubnetFrontendLogger")
    }
  })
  .catch(error => {
    console.error("Error getting app deploy environment:", error)
  })

const rootContainer = document.getElementById("main")
const appRoot = ReactDOM.createRoot(rootContainer)

appRoot.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
)
