import storage from "local-storage-fallback"
import request from "superagent"
import history from "./history"
import AccessManager from "./AccessManager"
import { SEARCH_TYPE_ISSUE, SEARCH_TYPE_TITLE } from "./constants"
import { compareDesc, differenceInDays } from "date-fns"
import queryString from "query-string"
import { ChatMsgLocalStore } from "./chat/chat-utils"

export class API {
  constructor() {
    window.addEventListener("storage", event => {
      // Reload the page only when the access token is set for the first time(login)
      // or its removed(logout)
      if (
        (event.key === "expiresAt" || event.key === "accessToken") &&
        (event.oldValue === null || event.newValue === null)
      ) {
        window.location.reload()
      }
    })
    this.accessManager = new AccessManager()
  }
  initAccessManager() {
    if (this.accessManager) {
      this.accessManager.init()
    }
  }
  requestWithAccId(method, url) {
    let req = request(method, url)
    const token = storage.getItem("accessToken")
    if (token) {
      req = req.auth(token, {
        type: "bearer"
      })
    }
    const aid = storage.getItem("subnetAccountId")
    if (aid) {
      req = req.query({ aid: aid })
    }
    return req
  }
  makeRequest(method, url, data) {
    return this.requestWithAccId(method, url)
      .send(data)
      .then(res => res.body)
      .catch(err => this.errorHandler(err))
  }
  errorHandler = err => {
    if (err.status) {
      console.log(`Server returned error [${err.status}]`)
      if (err.status === "401" || err.status === 401) {
        console.log(
          "Server returned unauthorized, checking access token expiry"
        )
        const sessionExpired = this.checkTokenExpiry()
        if (sessionExpired) {
          const exDays = this.daysSinceTokenExpired()
          console.log("Access token expired since ", exDays)
          // The refresh token will be valid for a week even if the access token is expired
          if (exDays < 7) {
            console.log("Access token expired, waiting for renewal")
            return this.getErrPromise(err)
          }
        }
        this.Logout()
        if (sessionExpired) {
          const pushConfig = {
            expired: true,
            redirectTo: window.location.pathname
          }
          history.push("/", pushConfig)
        } else {
          const pushConfig = {
            unauthorized: true,
            redirectTo: window.location.pathname
          }
          history.push("/", pushConfig)
        }
      } else if (
        parseInt(err.status) === 503 &&
        !window.location.pathname.startsWith("/mnt")
      ) {
        // If the url is maintenance admin page (/mnt),
        // we will not do the redirect
        history.push("/maintenance")
      }
      return this.getErrPromise(err)
    } else {
      let errorMsg = ""
      if (navigator && navigator.onLine) {
        errorMsg = err.message || "Unknown error"
        console.log("Error: " + errorMsg)
        return Promise.reject({ error: errorMsg, offline: false })
      } else {
        errorMsg =
          "Server is unreachable, please check your internet connection"
        console.log("Error: " + errorMsg)
        return Promise.reject({ error: errorMsg, offline: true })
      }
    }
  }
  getErrPromise(err) {
    const errorMsg =
      (err.response.body && err.response.body.error) || err.status
    console.log("Error: " + errorMsg)
    return Promise.reject({
      error: errorMsg,
      offline: false,
      status: err.status
    })
  }
  getHtml() {
    const url = "/index.html"
    return request("GET", url).then(res => res.text)
  }
  getMaintenanceStatus() {
    const url = "/api/maintenance/status"
    return request
      .get(url)
      .then(res => res.body)
      .catch(this.errorHandler)
  }
  SendInvite(inviteList, accountId) {
    const url = "/api/auth/signup/init"
    const data = {
      users: inviteList,
      account_id: accountId
    }
    return this.makeRequest("POST", url, data)
  }
  Login(username, password, isMaintenance) {
    const url = isMaintenance ? "/api/maintenance/login" : "/api/auth/login"
    return request
      .post(url)
      .send({ username: username, password: password })
      .then(res => res)
      .catch(this.errorHandler)
  }
  MfaLogin({ username, mfaToken, otp, isMaintenance }) {
    const url = isMaintenance
      ? "/api/maintenance/mfa-login"
      : "/api/auth/mfa-login"
    return request
      .post(url)
      .send({
        username: username,
        token: mfaToken,
        otp: otp
      })
      .then(res => res)
      .catch(this.errorHandler)
  }

  ResendOtpForAuth(mfaToken, isMaintenance) {
    const url = isMaintenance
      ? "/api/maintenance/resend-otp"
      : "/api/auth/resend-otp"
    return this.makeRequest("POST", url, {
      mfa_token: mfaToken
    })
  }
  getTokenExpiresAt() {
    const expAt = storage.getItem("expiresAt")
    return expAt
  }
  setAccessToken({ expires_in }) {
    const expiresAt = Date.now() + expires_in * 1000
    storage.setItem("expiresAt", expiresAt)
  }
  refreshAccessToken(lockOptions = {}) {
    const url = "/api/auth/refresh-token"
    return navigator.locks.request("renew-access-token", lockOptions, lock => {
      if (!lock) {
        return
      }
      console.log("Lock acquired to renew the refresh token")
      return request("POST", url)
        .then(res => {
          this.setAccessToken(res.body)
          console.log("Successfully renewed the refresh token")
        })
        .catch(err => {
          console.log(
            "Failed to renew the refresh-token: ",
            err.status,
            err?.response?.body?.error
          )
          if (err.status) {
            // Check if the renewal failed due to invalid refresh token
            if (err.status === "401" || err.status === 401) {
              console.log("Failed to renew the refresh-token, logging out")
              api.Logout()
              history.push("/")
              return this.getErrPromise(err)
            }
          }
        })
    })
  }
  Logout() {
    storage.removeItem("expiresAt")
    storage.removeItem("subnetAccountId")
    ChatMsgLocalStore.resetDrafts()

    if (this.accessManager) {
      this.accessManager.clearTimer()
    }
    return this.makeRequest("POST", "/api/auth/logout")
  }
  LoggedIn() {
    return !!storage.getItem("expiresAt")
  }
  checkTokenExpiry() {
    const currentDate = Date.now()
    const expiresAt = parseFloat(storage.getItem("expiresAt"))
    return currentDate > expiresAt
  }
  daysSinceTokenExpired() {
    const expiresAt = storage.getItem("expiresAt")
    if (expiresAt) {
      return differenceInDays(new Date(), Number.parseInt(expiresAt))
    }
  }
  GetUserInfo() {
    const url = "/api/chat/user"
    return this.makeRequest("GET", url)
  }
  GetProfile() {
    const url = "/api/chat/profile"
    return this.makeRequest("GET", url)
  }
  RemoveProfilePicture() {
    const url = "/api/chat/profile/picture"
    return this.makeRequest("DELETE", url)
  }
  UploadProfileImage(image, progressCallback, cancel) {
    const url = "/api/chat/profile"
    const req = this.requestWithAccId("POST", url)
      .attach("profile_img", image)
      .on("progress", e => {
        progressCallback(e.percent)
        if (cancel() === true) {
          progressCallback(100)
          req.abort()
        }
      })
    return req.then(res => res.body).catch(this.errorHandler)
  }
  UpdateProfileDetails(
    name,
    phone = "",
    profile_timezone,
    countryCode,
    slackMemberId,
    expertiseLevel,
    emailNotificationsEnabled
  ) {
    const url = "/api/chat/profile"
    const updateProfileReq = this.requestWithAccId("POST", url)
    if (name) {
      updateProfileReq.field("profile_name", name)
    }
    if (profile_timezone) {
      updateProfileReq.field("profile_timezone", profile_timezone)
    }
    if (countryCode) {
      updateProfileReq.field("profile_country_code", countryCode)
    }
    if (slackMemberId) {
      updateProfileReq.field("profile_slack_member_id", slackMemberId)
    }
    if (expertiseLevel) {
      updateProfileReq.field("profile_expertise_cat_id", expertiseLevel)
    }
    if (emailNotificationsEnabled) {
      updateProfileReq.field(
        "email_notifications_enabled",
        emailNotificationsEnabled
      )
    }
    updateProfileReq.field("profile_phone", phone)

    return updateProfileReq.then(res => res.body).catch(this.errorHandler)
  }
  verifyPhone(phone) {
    const url = "/api/chat/phone/verification"
    const data = {
      profile_phone: phone
    }
    return this.makeRequest("POST", url, data)
  }
  verifyCode(phone, code) {
    const url = "/api/chat/phone/verificationcheck"
    const data = {
      profile_phone: phone,
      verify_code: code
    }
    return this.makeRequest("POST", url, data)
  }
  isPanicAllowed() {
    const url = "/api/chat/ispanicallowed"
    return this.makeRequest("GET", url)
  }
  sendPanic(title, message) {
    const url = "/api/chat/panic"
    const data = {
      panic_title: title,
      panic_message: message
    }
    return this.makeRequest("POST", url, data)
  }
  getEngineersActivity(days) {
    const qparam = days ? `?days=${days}` : ""
    const url = `/api/chat/users/activity${qparam}`
    return this.makeRequest("GET", url)
  }
  getEngineersActivityV2() {
    const url = "/api/chat/users/activity/v2"
    return this.makeRequest("GET", url)
  }
  listPurgeAccounts() {
    const url = " /api/auth/purge"
    return this.makeRequest("GET", url)
  }
  getPurgeDetails(accountId) {
    const url = `/api/auth/purge/${accountId}`
    return this.makeRequest("GET", url)
  }
  getPurgedAccountInfo(accountId) {
    const url = `/api/auth/purge/${accountId}/info`
    return this.makeRequest("GET", url)
  }
  getAccounts() {
    const url = "/api/chat/accounts"
    return this.makeRequest("GET", url)
  }
  getAccountsInfo() {
    const url = "/api/auth/accountsinfo"
    return this.makeRequest("GET", url)
  }
  getTeams() {
    const url = "/api/auth/teams"
    return this.makeRequest("GET", url)
  }
  getAccountDetails(accountId) {
    const url = `/api/auth/accountsinfo/${accountId}`
    return this.makeRequest("GET", url)
  }
  getAccountUserInfo() {
    const url = "/api/auth/account-users"
    return this.makeRequest("GET", url)
  }
  getUsersWithoutMembership() {
    const url = "/api/auth/accounts/users-without-membership"
    return this.makeRequest("GET", url)
  }
  createAccount(accountData) {
    const url = "/api/auth/accounts"
    return this.makeRequest("POST", url, accountData)
  }
  reactivateAccount(accountId, accountData) {
    const url = `/api/auth/accounts/${accountId}/subscription`
    return this.makeRequest("POST", url, accountData)
  }
  updateAccount(accountId, accountData) {
    const url = `/api/auth/accounts/${accountId}`
    return this.makeRequest("POST", url, accountData)
  }
  upgradeAccount(accountId, accountData) {
    const url = `/api/auth/accounts/${accountId}/subscription`
    return this.makeRequest("PUT", url, accountData)
  }
  endCustomSubscription(accountId, endData) {
    const url = `/api/auth/subscription/end/${accountId}`
    return this.makeRequest("POST", url, endData)
  }
  purgeAccount(accountId) {
    const url = `/api/auth/accounts/${accountId}`
    return this.makeRequest("DELETE", url)
  }
  disableAccount(accountId) {
    const url = `/api/auth/account/${accountId}`
    return this.makeRequest("POST", url, { disable: true })
  }
  enableAccount(accountId) {
    const url = `/api/auth/account/${accountId}`
    return this.makeRequest("POST", url, { disable: false })
  }
  joinIssue(issueNumber) {
    const url = "/api/chat/issue/" + issueNumber + "/join"
    return this.makeRequest("POST", url)
  }
  deleteMessage(issueNumber, messageId) {
    const url = `/api/chat/issue/${issueNumber}/message/${messageId}`
    return this.makeRequest("DELETE", url)
  }
  forceFormatMessage(issueNumber, msgId, fmt = true) {
    const url = `/api/chat/issue/${issueNumber}/message/${msgId}`
    return this.makeRequest("PUT", url, { force_fmt_msg: fmt })
  }
  getUsers(accountId) {
    const qparam = accountId ? `?account_id=${accountId}` : ""
    const url = `/api/chat/users${qparam}`
    return this.makeRequest("GET", url)
  }
  getEngineers() {
    const url = "/api/chat/engineers"
    return this.makeRequest("GET", url)
  }
  getUserThumbnail(userId) {
    const url = `/api/chat/user/${userId}/thumbnail`
    return this.requestWithAccId("GET", url)
      .responseType("blob")
      .then(res => res.body)
      .catch(this.errorHandler)
  }
  getChatAuthToken() {
    const url = `/api/chat/token?endpoint_id=browser`
    return this.makeRequest("GET", url)
  }
  createIssue(title, accountId, message, clusterId) {
    let data = {
      title: title,
      accountId: accountId,
      message: message
    }

    if (clusterId) {
      data = {
        ...data,
        clusterId: clusterId
      }
    }
    const url = "/api/chat/issue"
    return this.makeRequest("POST", url, data)
  }

  sendNotification({
    accountIds,
    typeId,
    title,
    message,
    htmlContent,
    isCreateAlert = true
  }) {
    const data = {
      accountIds: accountIds,
      typeId: typeId,
      title: title,
      message: message,
      htmlContent: htmlContent,
      isSendEmail: true,
      isCreateAlert: isCreateAlert
    }
    const url = "/api/chat/alerts"
    return this.makeRequest("POST", url, data)
  }
  getIssue(issueNumber) {
    const url = `/api/chat/issue/${issueNumber}`
    return this.makeRequest("GET", url)
  }
  listIssues(queryString) {
    const url = `/api/chat/issue?${queryString}`
    return this.makeRequest("GET", url)
  }
  updateIssueTitle(issueNumber, title) {
    const url = `/api/chat/issue/${issueNumber}`
    return this.makeRequest("POST", url, { title: title })
  }
  updateIssueWeekendPriority(issueNumber, isPriority) {
    const url = `/api/chat/issue/${issueNumber}`
    return this.makeRequest("POST", url, { weekend_priority: isPriority })
  }
  markIssueAnswered(issueNumber, type) {
    const url = `/api/chat/issue/${issueNumber}/answer`
    const date = new Date()
    const data = {
      type: type,
      timestamp: date.toISOString()
    }
    return this.makeRequest("POST", url, data)
  }
  uploadAttachment(
    accountId,
    issueNumber,
    filename,
    file,
    progressCallback,
    cancel
  ) {
    const url = `/api/objects/${accountId}/${issueNumber}/${filename}`

    const req = this.requestWithAccId("POST", url)
      .attach("attachment", file)
      .on("progress", e => {
        progressCallback(e.percent)
        if (cancel() === true) {
          progressCallback(100) // complete the cancel
          req.abort()
        }
      })
    return req.then(res => res.body).catch(this.errorHandler)
  }
  getAttachmentUrl(accountId, issueNumber, uploadId, filename, is_view = true) {
    const url = `/api/objects/${accountId}/${issueNumber}/${uploadId}/${encodeURIComponent(
      filename
    )}`

    const params = queryString.stringify(
      {
        aid: storage.getItem("subnetAccountId"),
        is_view: is_view ? "true" : "false"
      },
      { skipNull: true }
    )

    return `${url}?${params}`
  }
  search(type, query, issueNum, accountId) {
    let data = {
      type: type,
      query: query
    }
    const url = "/api/chat/search"
    if (type === SEARCH_TYPE_ISSUE && issueNum !== undefined) {
      data = {
        ...data,
        issue_num: issueNum
      }
    } else if (type !== SEARCH_TYPE_ISSUE && accountId !== undefined) {
      data = {
        ...data,
        account_id: accountId
      }
    }
    return this.makeRequest("POST", url, data).then(res => {
      if (type === SEARCH_TYPE_TITLE) {
        return res.titles.sort((i1, i2) =>
          compareDesc(new Date(i1.createdAt), new Date(i2.createdAt))
        )
      } else {
        return res.messages
          .reduce((list, issue) => {
            const { twilioMessages, ...issueDetails } = issue
            const messages = twilioMessages.map(tm => ({
              message: tm,
              ...issueDetails
            }))
            return [...list, ...messages]
          }, [])
          .sort((m1, m2) =>
            compareDesc(
              new Date(m1.message.date_created),
              new Date(m2.message.date_created)
            )
          )
      }
    })
  }

  toggleActiveUser(userId, status) {
    const url = `/api/chat/user/${userId}`
    return this.makeRequest("POST", url, { enable: status })
  }

  removeUserMembership(accountId, userId) {
    const url = `/api/auth/accounts/${accountId}/user/${userId}`
    return this.makeRequest("DELETE", url)
  }

  cancelInvite(inviteId) {
    const url = `/api/auth/invites/${inviteId}`
    return this.makeRequest("DELETE", url)
  }

  SetAccountOwner(email, accountId, makeAdmin) {
    const qparam = accountId ? `?account_id=${accountId}` : ""
    const url = `/api/auth/set-account-owner${qparam}`
    return this.makeRequest("POST", url, {
      email: email,
      is_account_owner: makeAdmin
    })
  }
  ValidateSignupToken(email, token) {
    const url = `/api/auth/signup/verify?email=${email}&token=${token}`
    return this.makeRequest("GET", url)
  }
  Signup(
    email,
    token,
    name,
    password,
    teamName,
    consentStatus,
    countryCode,
    timezone
  ) {
    const url = "/api/auth/signup"
    let data = {
      email: email,
      token: token,
      name: name,
      password: password,
      consent_received: consentStatus,
      country_code: countryCode,
      timezone: timezone
    }

    if (teamName) {
      data = {
        ...data,
        team_name: teamName
      }
    }
    return this.makeRequest("POST", url, data)
  }
  JoinAccount(teamName, token, consentStatus) {
    const url = "/api/auth/join-account"
    return this.makeRequest("POST", url, {
      token: token,
      team_name: teamName,
      consent_received: consentStatus
    })
  }
  ForgotPassword(email) {
    const url = "/api/auth/forgot-password"
    return this.makeRequest("POST", url, {
      email: email
    })
  }
  ResetPassword(email, token, password) {
    const url = "/api/auth/reset-password"
    return this.makeRequest("POST", url, {
      email: email,
      token: token,
      password: password
    })
  }
  ChangePassword(email, oldpassword, newpassword) {
    const url = "/api/auth/change-password"
    return this.makeRequest("POST", url, {
      email: email,
      current_password: oldpassword,
      new_password: newpassword
    })
  }

  GetPaymentSubscription() {
    const url = "/api/auth/subscription"
    return this.makeRequest("GET", url)
  }
  CancelPaymentSubscription() {
    const url = "/api/auth/subscription/cancel"
    return this.makeRequest("POST", url)
  }
  GetCancelSubscriptionDetails() {
    const url = "/api/auth/subscription/cancel"
    return this.makeRequest("GET", url)
  }
  UndoCancelPaymentSubscription() {
    const url = "/api/auth/subscription/undo-cancel"
    return this.makeRequest("POST", url)
  }
  autoRenewSubscription(enabled) {
    const url = `/api/auth/subscription/auto_renew`
    return this.makeRequest("POST", url, {
      enabled: enabled
    })
  }
  GetSubscriptionRenewalEstimate() {
    const url = "/api/auth/subscription/estimate/renewal"
    return this.makeRequest("GET", url)
  }
  GetSubscriptionEstimate(serviceType, capacity, currency) {
    const url = `/api/auth/subscription/estimate?service_type=${serviceType}&capacity=${capacity}&currency=${currency}`
    return this.makeRequest("GET", url)
  }
  UpdateQuantity(productId, quantity) {
    const url = `/api/auth/subscription/updatequantity`
    return this.makeRequest("POST", url, {
      product_id: productId,
      quantity: quantity
    })
  }
  UpgradePlan(serviceType, consentStatus, capacity, paymentIntentId) {
    const url = `/api/auth/subscription/upgrade`
    return this.makeRequest("POST", url, {
      service_type: serviceType,
      consent_received: consentStatus,
      capacity: capacity,
      payment_intent_id: paymentIntentId
    })
  }
  PrepareUpgradePlan(serviceType, capacity) {
    const url = "/api/auth/subscription/upgrade/prepare"
    return this.makeRequest("POST", url, {
      service_type: serviceType,
      capacity: capacity
    })
  }

  GetUpgradeEstimates(serviceType, capacity) {
    const url = `/api/auth/subscription/estimate/upgrade?serviceType=${serviceType}&capacity=${capacity}`
    return this.makeRequest("GET", url)
  }
  ListProductsWithPlans() {
    const url = "/api/auth/subscription/productswithplans"
    return this.makeRequest("GET", url)
  }
  GetUpgradePlans() {
    const url = "/api/auth/subscription/upgrade/plans"
    return this.makeRequest("GET", url)
  }
  ListProducts(includeInactive = false) {
    const url = `/api/auth/subscription/products?includeInactive=${includeInactive}`
    return this.makeRequest("GET", url)
  }
  contactSales(enquiryDetails) {
    //this should not send auth token to api
    const url = "https://min.io/email"
    const serializedEnquiry = Object.keys(enquiryDetails)
      .map(key => key + "=" + encodeURIComponent(enquiryDetails[key]))
      .join("&")

    return request("POST", url)
      .send(serializedEnquiry)
      .then(res => res.body)
      .catch(err => this.errorHandler(err))
  }
  validateTeamName(organizationName, accountId) {
    const url = `/api/auth/validate-organization-name?org_name=${organizationName}&accountId=${accountId}`
    return this.makeRequest("GET", url)
  }
  getAcceptedPolicyDocs() {
    const url = `/api/auth/subscription/accepted_policy_docs`
    return this.makeRequest("GET", url)
  }

  getResubscribeChekoutUrl(details) {
    const url = `/api/auth/subscription/resubscribe_checkout`
    return this.makeRequest("POST", url, details)
  }
  applyResubscription(resubscribeRequest) {
    return api.makeRequest(
      "POST",
      "/api/auth/subscription/chargebee_resubscribe",
      resubscribeRequest
    )
  }

  getDeployEnv() {
    const url = "/api/auth/deploy_env"
    return this.makeRequest("GET", url)
  }

  updateOrgName(orgName, accountId) {
    let url = `/api/auth/update-organization-name`
    if (accountId) {
      url = `${url}?account_id=${accountId}`
    }
    return this.makeRequest("PUT", url, {
      organization_name: orgName
    })
  }

  uploadFile({ accountId, filename, fileType, file, size }) {
    const url = `/api/objects?file_type=${fileType}&account_id=${accountId}&filename=${filename}&size=${size}`
    const req = this.requestWithAccId("POST", url).attach("data", file)
    return req.then(res => res.body)
  }

  getFileUrl(accountId, filetype, uuid, filename) {
    const url = `/api/objects/${uuid}?file_type=${filetype}&account_id=${accountId}&filename=${decodeURIComponent(
      filename
    )}`
    const aid = storage.getItem("subnetAccountId")
    if (aid) {
      return url + `&aid=${aid}`
    } else {
      return url
    }
  }

  recordDocument({ accountId, uploadId, filename, title, file_type, size }) {
    const url = `/api/auth/accounts/${accountId}/files`
    const params = {
      file_type: file_type,
      title: title,
      filename: filename,
      uuid: uploadId,
      size: size
    }
    return this.makeRequest("POST", url, params)
  }

  getOrphanFiles(accountId) {
    let url = `/api/auth/accounts/${accountId}/files/orphan`
    return this.makeRequest("GET", url)
  }

  getFiles(accountId, fileType) {
    let url = `/api/auth/accounts/${accountId}/files`
    if (fileType) {
      url += `?file_type=${fileType}`
    }
    return this.makeRequest("GET", url)
  }

  deleteFile(accountId, uuid) {
    const url = `/api/auth/accounts/${accountId}/files/${uuid}`
    return this.makeRequest("DELETE", url)
  }

  editDocTitle(accountId, accountFileId, docTitle) {
    const url = `/api/auth/accounts/${accountId}/files/${accountFileId}`
    return this.makeRequest("PUT", url, { title: docTitle })
  }
  generateTrialLicenseKey({
    email,
    teamName,
    capacity = -1,
    expiryDate,
    purpose
  }) {
    const url = `/api/auth/subscription/license?email=${encodeURIComponent(
      email
    )}&capacity=${capacity}&teamName=${teamName}&expiryDate=${expiryDate}&purpose=${purpose}`
    return this.makeRequest("POST", url)
  }
  setSelectedAccountId(accountId) {
    storage.setItem("subnetAccountId", accountId)
  }
  clearAccountId() {
    storage.removeItem("subnetAccountId")
  }
  getOrganizations() {
    const url = "/api/auth/organizations"
    return this.makeRequest("GET", url)
  }

  getMessageByDate(issueNumber, dateStr) {
    const url = `/api/chat/issue/${issueNumber}/lookup-message?date=${dateStr}`
    return this.makeRequest("GET", url)
  }

  getAccountId() {
    return storage.getItem("subnetAccountId") // Engineer Admin Facing URLs need to pass it explicity
  }

  getRegisteredClusters(accountId) {
    let customerAccId = accountId
    const aid = this.getAccountId() // Engineer Admin Facing URLs need to pass it explicitly
    if (aid) {
      customerAccId = aid
    }
    const url = `/api/auth/accounts/${customerAccId}/clusters`
    return this.makeRequest("GET", url)
  }

  linkHealthReport({
    accountId,
    fileUuid,
    fileTitle,
    fileName,
    fileType,
    clusterName,
    clusterId,
    size
  }) {
    const url = `/api/auth/accounts/${accountId}/files`
    const params = {
      uuid: fileUuid,
      filename: fileName,
      title: fileTitle,
      file_type: fileType,
      account_id: accountId,
      cluster_name: clusterName,
      cluster_id: clusterId,
      size: size
    }
    return this.makeRequest("POST", url, params)
  }

  analyzeHealth({ accountId, reqType, clusterId }) {
    const url = `/api/health?accId=${accountId}&clusterId=${clusterId}&reqType=${reqType}`
    return this.makeRequest("GET", url)
  }

  updateClusterName({ accountId, clusterName, clusterId }) {
    const url = `/api/auth/accounts/${accountId}/clusters/${clusterId}`
    const params = {
      name: clusterName
    }
    return this.makeRequest("PUT", url, params)
  }

  getAllHealthChecks() {
    const url = `/api/health/checks`
    return this.makeRequest("GET", url)
  }

  deleteCluster({ accountId, clusterId }) {
    const url = `/api/auth/accounts/${accountId}/clusters/${clusterId}`
    return this.makeRequest("DELETE", url)
  }

  enableMaintenance(duration, message) {
    const url = "/api/maintenance/enable"
    const data = { duration: duration, message: message }
    return this.makeRequest("POST", url, data)
  }

  disableMaintenance() {
    const url = "/api/maintenance/disable"
    return this.makeRequest("POST", url)
  }
  getAlerts(page) {
    const url = `/api/chat/alerts?page=${page}`
    return this.makeRequest("GET", url)
  }
  getAlertsSummary() {
    const url = `/api/chat/alerts-summary`
    return this.makeRequest("GET", url)
  }
  markAlertAsRead(id) {
    const url = `/api/chat/alert/${id}`
    return this.makeRequest("PUT", url)
  }
  makeEngAdmin(email, isAdmin) {
    const url = `/api/auth/set-admin`
    return this.makeRequest("POST", url, { email: email, is_admin: isAdmin })
  }
  setTeam(email, teamId) {
    const url = `/api/auth/set-team`
    return this.makeRequest("POST", url, { email: email, team_id: teamId })
  }
  saveFeedback(accountId, data) {
    const url = `/api/auth/accounts/${accountId}/feedback`
    return this.makeRequest("POST", url, data)
  }
  listFeedback({ page = 0, sortBy, sortOrder, accountId }) {
    const params = queryString.stringify(
      {
        page: page,
        sort_by: sortBy,
        sort_order: sortOrder,
        account_id: accountId
      },
      { skipNull: true }
    )
    const url = `/api/auth/feedback?${params}`
    return this.makeRequest("GET", url)
  }
  setMfa(enableMfa) {
    const url = `/api/auth/mfa`
    const params = {
      enable_mfa: enableMfa
    }
    return this.makeRequest("POST", url, params)
  }
  getMfa() {
    const url = `/api/auth/mfa`
    return this.makeRequest("GET", url)
  }
  generateLicenseKey(accountId) {
    const url = `/api/auth/accounts/${accountId}/licenses`
    return this.makeRequest("POST", url, {})
  }
  listLicenses({ limit, after, before, accountId }) {
    const params = queryString.stringify(
      {
        limit: limit,
        after: after,
        before: before,
        account_id: accountId
      },
      { skipNull: true }
    )
    const url = `/api/auth/trial_licenses?${params}`
    return this.makeRequest("GET", url)
  }
  deleteLicense(accountId, licenseId) {
    const url = `/api/auth/accounts/${accountId}/licenses/${licenseId}`
    return this.makeRequest("DELETE", url)
  }
  getNotices(accountId) {
    const url = `/api/auth/accounts/${accountId}/notices`
    return this.makeRequest("GET", url)
  }

  getAssignees(issueId) {
    const url = `/api/chat/issues/${issueId}/assignees`
    return this.makeRequest("GET", url)
  }

  assignUserToIssue(issueId, userId) {
    const url = `/api/chat/issues/${issueId}/assignees`
    return this.makeRequest("POST", url, { user_id: userId })
  }
  unAssignUserFromIssue(issueId, userId) {
    const url = `/api/chat/issues/${issueId}/assignees/${userId}`
    return this.makeRequest("DELETE", url)
  }

  createAccountNotes(accountId) {
    let url = `/api/auth/accounts/${accountId}/notes`
    return this.makeRequest("PUT", url)
  }

  registerCluster(params) {
    let url = `/api/cluster/register`
    return this.makeRequest("POST", url, params)
  }

  getAccountApiKey() {
    let url = `/api/auth/api-key`
    return this.makeRequest("GET", url)
  }

  getAccountLicense() {
    let url = `/api/auth/license`
    return this.makeRequest("GET", url)
  }

  getSuscriptionEvents(accountId) {
    const url = `/api/auth/accounts/${accountId}/subscription_events`
    return this.makeRequest("GET", url)
  }

  getAccountAlerts({ accountId, before, after, limit }) {
    const params = queryString.stringify(
      {
        limit: limit,
        after: after,
        before: before
      },
      { skipNull: true }
    )

    const url = `/api/auth/accounts/${accountId}/alerts?${params}`
    return this.makeRequest("GET", url)
  }

  getChatAttachments(accountId, issueNumber) {
    const params = queryString.stringify(
      {
        issue_number: issueNumber
      },
      { skipNull: true }
    )
    const url = `/api/chat/accounts/${accountId}/attachments?${params}`
    return this.makeRequest("GET", url)
  }
  getIssueFollowingStatus(issueNumber) {
    const url = `/api/chat/issues/${issueNumber}/follow`
    return this.makeRequest("GET", url)
  }
  followIssue(issueNumber) {
    const url = `/api/chat/issues/${issueNumber}/follow`
    return this.makeRequest("POST", url)
  }
  unfollowIssue(issueNumber) {
    const url = `/api/chat/issues/${issueNumber}/unfollow`
    return this.makeRequest("POST", url)
  }
  getOrgFollowingStatus(accountId) {
    const url = `/api/chat/accounts/${accountId}/follow`
    return this.makeRequest("GET", url)
  }
  followOrg(accountId) {
    const url = `/api/chat/accounts/${accountId}/follow`
    return this.makeRequest("POST", url)
  }
  unfollowOrg(accountId) {
    const url = `/api/chat/accounts/${accountId}/unfollow`
    return this.makeRequest("POST", url)
  }

  getLogs({ deploymentId, limit, pageNum = 1, query = "", from, to }) {
    const searchParams = new URLSearchParams()

    searchParams.set("deploymentId", deploymentId)
    searchParams.set("limit", limit)
    if (pageNum >= 1) {
      searchParams.set("page", pageNum)
    }
    if (query.length > 0) {
      searchParams.set("query", query)
    }
    if (from) {
      searchParams.set("from", from)
    }
    if (to) {
      searchParams.set("to", to)
    }

    const queryString = searchParams.toString()
    const url = `/api/logs/list?${queryString}`

    return this.makeRequest("GET", url)
  }

  pinAttachment(accountId, attachId) {
    const url = `/api/chat/accounts/${accountId}/attachments/${attachId}/pin`
    return this.makeRequest("POST", url)
  }

  unPinAttachment(accountId, attachId) {
    const url = `/api/chat/accounts/${accountId}/attachments/${attachId}/unpin`
    return this.makeRequest("POST", url)
  }
  startCall(issue_number, meetingUrl) {
    const url = `/api/chat/issues/${issue_number}/meetings`

    const callInfo = { meeting_link: meetingUrl }
    return this.makeRequest("POST", url, callInfo)
  }

  callInfo(issue_number) {
    const url = `/api/chat/issues/${issue_number}/meetings`

    return this.makeRequest("GET", url)
  }

  endCall(issue_number, meetingId) {
    const url = `/api/chat/issues/${issue_number}/meetings/${meetingId}`
    return this.makeRequest("PUT", url, { end: true })
  }

  joinCall(meetingId) {
    const url = `/api/chat/meetings/${meetingId}/join`
    return this.makeRequest("POST", url)
  }

  listMeetings({ accountId, fromDate, toDate }) {
    const params = queryString.stringify(
      {
        account_id: accountId,
        from_date: fromDate,
        to_date: toDate
      },
      { skipNull: true }
    )
    const url = `/api/chat/meetings?${params}`
    return this.makeRequest("GET", url)
  }
  removeUsersWithoutMembership() {
    const url = `/api/auth/users`
    return this.makeRequest("DELETE", url)
  }

  listIssuesForExport(fromDate, toDate) {
    const params = queryString.stringify(
      {
        from_date: fromDate,
        to_date: toDate
      },
      { skipNull: true }
    )
    const url = `/api/chat/reports/issues?${params}`
    return this.makeRequest("GET", url)
  }
  getIssueParticipants(issueNumber) {
    const url = `/api/chat/issues/${issueNumber}/participants`
    return this.makeRequest("GET", url)
  }

  listClusterProfiles(accountId, clusterId) {
    const url = `/api/auth/accounts/${accountId}/files?file_type=profile&cluster_id=${clusterId}`
    return this.makeRequest("GET", url)
  }

  getProfileInfo(fileId, accountId) {
    const url = `/api/profile/info?fileId=${fileId}&accId=${accountId}`
    return this.makeRequest("GET", url)
  }
  uploadPerformanceInfo(
    file,
    filename,
    accountId,
    clusterId,
    progressCb,
    decideAbortFn
  ) {
    const url = `/api/perf/upload?filename=${filename}&account_id=${accountId}&cluster_id=${clusterId}`
    const req = this.requestWithAccId("POST", url)
      .attach("report", file)
      .on("progress", evt => {
        progressCb(evt)
        if (decideAbortFn()) {
          req.abort()
        }
      })
    return req.then(res => res.body).catch(this.errorHandler)
  }

  listClusterPerformance(accountId, clusterId) {
    const url = `/api/auth/accounts/${accountId}/files?file_type=perf_result&cluster_id=${clusterId}`
    return this.makeRequest("GET", url)
  }

  getPerfResults(fileId, accountId) {
    const url = ` /api/perf/results?accId=${accountId}&fileId=${fileId}`
    return this.makeRequest("GET", url)
  }

  uploadInspectFile(
    file,
    filename,
    accountId,
    clusterId,
    progressCb,
    decideAbortFn
  ) {
    const url = `/api/inspect/upload?filename=${filename}&account_id=${accountId}&cluster_id=${clusterId}`
    const req = this.requestWithAccId("POST", url)
      .attach("file", file)
      .on("progress", evt => {
        progressCb(evt)
        if (decideAbortFn()) {
          req.abort()
        }
      })
    return req.then(res => res.body).catch(this.errorHandler)
  }

  uploadProfileFile(
    file,
    filename,
    accountId,
    clusterId,
    progressCb,
    decideAbortFn
  ) {
    const url = `/api/profile/upload?filename=${filename}&account_id=${accountId}&cluster_id=${clusterId}`
    const req = this.requestWithAccId("POST", url)
      .attach("file", file)
      .on("progress", evt => {
        progressCb(evt)
        if (decideAbortFn()) {
          req.abort()
        }
      })

    return req.then(res => res.body).catch(this.errorHandler)
  }

  listInspectFiles(accountId, clusterId) {
    const url = `/api/auth/accounts/${accountId}/files?file_type=inspect&cluster_id=${clusterId}`
    return this.makeRequest("GET", url)
  }
  listToDos() {
    const url = `/api/chat/todos`
    return this.makeRequest("GET", url)
  }
  updateTodo(todoId, { done, ...restFields }) {
    const url = `/api/chat/todos/${todoId}`
    const data = {
      is_done: done,
      ...restFields
    }
    return this.makeRequest("PUT", url, data)
  }
  deleteTodo(todoId) {
    const url = `/api/chat/todos/${todoId}`
    return this.makeRequest("DELETE", url)
  }
  createTodo(accountId, title, description, owner, issueNumber, msgIndex) {
    const data = {
      account_id: accountId,
      title: title,
      description: description,
      owner: owner,
      issue_number: issueNumber,
      msg_index: msgIndex
    }
    const url = "/api/chat/todos"
    return this.makeRequest("POST", url, data)
  }

  getTodoSummary() {
    const url = `/api/chat/todos-summary`
    return this.makeRequest("GET", url)
  }
  exportAccounts() {
    const url = "/api/auth/reports/accounts"
    return this.makeRequest("GET", url)
  }
  exportIssueResponseTime(accountId) {
    const url = `/api/chat/reports/accounts/${accountId}/issue-response-time`
    return this.makeRequest("GET", url)
  }
  exportClusterCapacities(accountId) {
    const url = `/api/auth/reports/accounts/${accountId}/capacities`
    return this.makeRequest("POST", url)
  }
  updateEngineerAvailability(userId, isAvailable, isContributor) {
    const data = {
      is_available: isAvailable,
      is_contributor: isContributor
    }
    const url = `/api/chat/engineers/${userId}`
    return this.makeRequest("PUT", url, data)
  }
  exportClusters() {
    const url = "/api/auth/reports/clusters"
    return this.makeRequest("GET", url)
  }
  getSystemAlert() {
    const url = "/api/auth/system-alert"
    return this.makeRequest("GET", url)
  }
  createSystemAlert(message) {
    const data = {
      message: message
    }
    const url = "/api/auth/system-alerts"
    return this.makeRequest("POST", url, data)
  }
  deleteSystemAlert(alertId) {
    const url = `/api/auth/system-alerts/${alertId}`
    return this.makeRequest("DELETE", url)
  }
  linkIssueToCluster(issueNumber, clusterId) {
    const url = `/api/chat/issues/${issueNumber}`
    return this.makeRequest("PUT", url, { cluster_id: clusterId })
  }
  getAlertCategories() {
    const url = `/api/auth/alert_categories`
    return this.makeRequest("GET", url)
  }
  getAlertTypes() {
    const url = `/api/auth/alert_types`
    return this.makeRequest("GET", url)
  }

  getAlertSubscriptions(userId) {
    const url = `/api/auth/users/${userId}/alert_subscriptions`
    return this.makeRequest("GET", url)
  }
  updateAlertSubscriptions(userId, subscriptions) {
    const url = `/api/auth/users/${userId}/alert_subscriptions`
    return this.makeRequest("PUT", url, subscriptions)
  }
  markAllAlertsAsRead() {
    const url = `/api/chat/alerts/read-all`
    return this.makeRequest("PUT", url)
  }

  getClusterRecentFiles(accountId, clusterId, fileType = "") {
    const url = `/api/auth/accounts/${accountId}/clusters/${clusterId}/recentFiles?fileType=${fileType}`
    return this.makeRequest("GET", url)
  }
  saveMeetingDetails({
    accountId,
    issueNumber,
    startTime,
    endTime,
    participants = [],
    meetingUrl = ""
  }) {
    let url = `/api/chat/accounts/${accountId}/log-meeting`
    const params = {
      issue_number: issueNumber,
      start_time: startTime,
      end_time: endTime,
      participants: participants,
      meeting_url: meetingUrl
    }
    return this.makeRequest("POST", url, params)
  }

  getFollowingAccounts() {
    const url = `/api/auth/accounts/following`
    return this.makeRequest("GET", url)
  }
  updateAccountManagementInfo(accountId, accMgmtInfo) {
    const url = `/api/auth/accounts/${accountId}/mgmt-info`
    return this.makeRequest("PUT", url, accMgmtInfo)
  }
  getUseCases() {
    const url = `/api/auth/use-cases`
    return this.makeRequest("GET", url)
  }
  saveUseCase(use_case) {
    const url = `/api/auth/use-cases`
    return this.makeRequest("POST", url, { use_case: use_case })
  }
  updateUseCase(useCaseId, use_case) {
    const url = `/api/auth/use-cases/${useCaseId}`
    return this.makeRequest("PUT", url, { use_case: use_case })
  }
  getClusterInfo(accountId, clusterId) {
    const url = `/api/auth/accounts/${accountId}/clusters/${clusterId}`
    return this.makeRequest("GET", url)
  }

  saveIssueMessageNotes({ issue_number, message_id, notes }) {
    const url = `/api/chat/issues/${issue_number}/messages/${message_id}/notes`
    return this.makeRequest("POST", url, {
      notes
    })
  }

  updateIssueMessageNotes({ issue_number, message_id, notes }) {
    const url = `/api/chat/issues/${issue_number}/messages/${message_id}/notes`
    return this.makeRequest("PUT", url, {
      notes
    })
  }

  fetchMessageNotes({ issue_number, message_id }) {
    const url = `/api/chat/issues/${issue_number}/messages/${message_id}/notes`
    return this.makeRequest("GET", url)
  }

  fetchIssueNotes({ issue_number }) {
    const url = `/api/chat/issues/${issue_number}/notes`
    return this.makeRequest("GET", url)
  }

  listCategories() {
    const url = `/api/auth/issue-categories`
    return this.makeRequest("GET", url)
  }
  updateIssueCategory({ issue_number, categoryId }) {
    const url = `/api/chat/issues/${issue_number}/category`
    return this.makeRequest("PUT", url, {
      category_id: categoryId
    })
  }

  updateEngineerExpertise(userId, categoryId) {
    const url = `/api/auth/users/${userId}/expertise`
    return this.makeRequest("PUT", url, {
      category_id: categoryId
    })
  }

  summarizeIssue(issue_number) {
    const url = `/api/llm/issues/${issue_number}/summarize`
    return this.makeRequest("POST", url)
  }

  notifyViaEmail(issue_number) {
    const url = `/api/chat/issues/${issue_number}/notify`
    return this.makeRequest("POST", url)
  }

  getIssueTags() {
    const url = `/api/chat/issue-tags`
    return this.makeRequest("GET", url)
  }
  saveIssueTag(tagName) {
    const url = `/api/chat/issue-tags`
    return this.makeRequest("POST", url, { tag: tagName })
  }
  renameIssueTag(tagId, newTagName) {
    const url = `/api/chat/issue-tags/${tagId}`
    return this.makeRequest("PUT", url, { tag: newTagName })
  }

  deleteIssueTag(tagName) {
    const url = `/api/chat/issue-tags/${tagName}`
    return this.makeRequest("DELETE", url)
  }
  getAssociatedIssueTags(issueNum) {
    const url = `/api/chat/issue/${issueNum}/tags`
    return this.makeRequest("GET", url)
  }
  associateTagToIssue(issueNum, tagName) {
    const url = `/api/chat/issue/${issueNum}/tags`
    return this.makeRequest("POST", url, { tag: tagName })
  }
  removeAssociatedIssueTag(issueNum, tagName) {
    const url = `/api/chat/issue/${issueNum}/tags/${tagName}`
    return this.makeRequest("DELETE", url)
  }
}

const api = new API()
export default api
