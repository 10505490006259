import { useState } from "react"

const useModalState = (): [boolean, () => void, () => void] => {
  const [isOpen, setOpen] = useState(false)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)
  return [isOpen, openModal, closeModal]
}

export default useModalState
